import { Cookies } from 'react-cookie'
const cookies = new Cookies()

export const getToken = (name: string) => {
  return cookies.get(name, { doNotParse: true })
}

export const setToken = (name: string, value: any, age: any) => {
  return cookies.set(name, value, { path: '/', maxAge: age })
}

export const removeToken = (name: string) => {
  return cookies.remove(name, { path: '/' })
}
