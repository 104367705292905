import { ProductReviewDetailDtoType, ProductReviewType, product } from '../../models/product'
import { ResponseData } from '../../models/types'
import Network from '../Network'
import axiosClientAdmin from '../request'

const productDetailAPI = {
  getProductDetail(slug: string): Promise<ResponseData<product>> {
    return axiosClientAdmin.get(Network.productDetail.getItem + slug)
  },
  getProductReview(params: ProductReviewDetailDtoType): Promise<ResponseData<ProductReviewType>> {
    return axiosClientAdmin.get(Network.productDetail.getItemReview + params.slug, { params: params })
  },
  getProductPreview(params: any): Promise<ResponseData<product>> {
    return axiosClientAdmin.get(Network.productDetail.getItem + params.slug, { params: params })
  },
}

export default productDetailAPI
