import Network from '../Network'
import axiosClientAdmin from '../request'
import { Responses } from '../../models/types'
import { NewsDetailDataType } from '../../models/newsDetailType'

const newsDetailAPI = {
  getNewsDetail(url: string): Promise<Responses<NewsDetailDataType>> {
    return axiosClientAdmin.get(Network.news.newsDetailItem + url)
  },
}
export default newsDetailAPI
