import { createSlice } from '@reduxjs/toolkit'
import {
  addCartItem,
  addWishListItemToCart,
  deleteItem,
  getCartDetail,
  updateCartItem,
} from '../../../app/features/cart/cart-api'
import { RootState } from '../../../app/store'
import { CartType, FrequencyType } from '../../../models/cart'

export const slice = createSlice({
  name: 'fastCart',
  initialState: {
    loading: false,
    cartData: undefined as CartType | undefined,
    listProductCollapse: [] as any,
    frequency: undefined as FrequencyType | undefined,
  },
  reducers: {
    changeListProductCollapse: (state, action) => {
      state.listProductCollapse = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCartDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(getCartDetail.fulfilled, (state, action) => {
        const { data, code } = action.payload
        if (code === 200) {
          if (data?.cart) {
            state.cartData = data?.cart
          }
          if (data?.frequency) {
            state.frequency = data?.frequency
          }
        }
        state.loading = false
      })
      .addCase(getCartDetail.rejected, (state) => {
        state.loading = false
      })
    // update item
    builder
      .addCase(updateCartItem.pending, (state) => {
        state.loading = true
      })
      .addCase(updateCartItem.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateCartItem.rejected, (state) => {
        state.loading = false
      })
    // add item
    builder
      .addCase(addCartItem.pending, (state) => {
        state.loading = true
      })
      .addCase(addCartItem.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(addCartItem.rejected, (state) => {
        state.loading = false
      })
    // delete item
    builder
      .addCase(deleteItem.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteItem.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteItem.rejected, (state) => {
        state.loading = false
      })
    builder
      .addCase(addWishListItemToCart.pending, (state) => {
        state.loading = true
      })
      .addCase(addWishListItemToCart.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(addWishListItemToCart.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectCartData = (state: RootState) => state[slice.name].cartData
export const selectListProductCollapse = (state: RootState) => state[slice.name].listProductCollapse
export const selectFrequency = (state: RootState) => state[slice.name].frequency
export const selectIsLoading = (state: RootState) => state[slice.name].loading

export const { changeListProductCollapse } = slice.actions

export const FastCartReducer = slice.reducer
