import {
  BriefProduct,
  GetCagetoryDto,
  GetCampaignDto,
  GetSuggestTodayDto,
  SimilarProductType,
} from '../../models/product'
import { ResponseData } from '../../models/types'
import Network from '../Network'
import axiosClientAdmin from '../request'

const productListAPI = {
  getSimilarList(slug: string): Promise<ResponseData<SimilarProductType[]>> {
    return axiosClientAdmin.get(Network.listProduct.getSimilarList + slug)
  },
  getRecommendList(slug: string): Promise<ResponseData<SimilarProductType[]>> {
    return axiosClientAdmin.get(Network.productDetail.getRecommendList + slug)
  },
  getSeenList(): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.get(Network.listProduct.getSeenList)
  },
  getCampaignList(params: GetCampaignDto): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.post(Network.listProduct.getCampaignList, params)
  },
  getFlashSaleList(params: GetCampaignDto): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.post(Network.listProduct.getFlashSaleList, params)
  },
  getCategoryList(params: GetCagetoryDto): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.post(Network.listProduct.getCategoryList, params)
  },
  getSuggestTodayList(params: GetSuggestTodayDto): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.post(Network.listProduct.getSuggestTodayList, params)
  },
  getMostDealList(): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.get(Network.listProduct.getMostDealList)
  },
  getSuggestHome(): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.get(Network.listProduct.getSuggestHome)
  },
}

export default productListAPI
