import arrow_menu from '../../assets/icons/arrow-menu.png'
import arrow_menu_down from '../../assets/icons/ic_menu_down.png'
import ic_prescription from '../../assets/icons/ic_prescription.png'
import ic_call from '../../assets/icons/ic_call.png'
import '../../assets/scss/menu.scss'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTER } from '../../router/config'

const Menu: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const menuList = [
    {
      name: 'header.menu.omiPharma',
      subItem: [
        { name: 'header.menu.abouOmiPharma', link: ROUTER.ABOUT_OMI },
        { name: 'header.menu.companyNews', link: ROUTER.NEWS_SCREEN },
      ],
    },
    // {
    //   name: 'header.menu.medicineSearch',
    //   link: ROUTER.DRUG_SEARCH_SCREEN,
    // },
    {
      name: 'header.menu.healthy',
      link: ROUTER.HEALTH,
    },
  ]

  return (
    <div className='menu-header'>
      <div className='menu-header__main-menu'>
        <ul className='main-menu__ul'>
          {menuList.map((item, index) => {
            return (
              <li key={index} className='main-menu__li' onClick={item.link ? () => navigate(item.link) : undefined}>
                <div>
                  {t(item.name)}
                  {item.subItem && (
                    <>
                      <img className='pl-1 arrow-menu-next' src={arrow_menu} alt='arrow-menu' />
                      <img className='pl-1 arrow-menu-down' src={arrow_menu_down} alt='arrow-menu' />
                    </>
                  )}
                  {item.subItem && (
                    <ul className='sub-menu'>
                      {item.subItem.map((subItem, i) => {
                        return (
                          <li key={i}>
                            <Link to={subItem.link}>{t(subItem.name)}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
        <div className='menu-header__right'>
          <div className='menu-header__phone-group'>
            <Link to='#'>
              <div className='menu-header__phone-group__btn'>
                <img src={ic_call} alt='icon call' />
                <div className='menu-header__phone-group__text'>
                  <p>{t('header.menu.hotline')}</p>
                  <a
                    className='font-weight-bold menu-header__phone-group__phone'
                    href={`tel:${t('header.menu.hotlineNumber')}`}
                  >
                    {t('header.menu.hotlineNumber')}
                  </a>
                </div>
              </div>
            </Link>
          </div>
          <div className='menu-header__prescription'>
            <Link to={ROUTER.PRESCRIPTION_SCREEN}>
              <div className='menu-header__prescription__btn'>
                <img src={ic_prescription} alt='arrow-menu' />
                <div className='menu-header__prescription__text'>{t('header.menu.uploadPrescription')}</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
