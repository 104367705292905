import Network from '../Network'
import axiosClientAdmin from '../request'
import { Responses } from '../../models/types'
import { ReqDrugKnowledge, dataDetailDrugKnowledge } from '../../models/typeCategory'

const categoryAPI = {
  fetchDrugKnowledge(): Promise<Responses<ReqDrugKnowledge>> {
    return axiosClientAdmin.get(Network.category.drugKnowledge, { params: { type: 2 } })
  },
  getDetailDrugKnowledge(url: string): Promise<Responses<dataDetailDrugKnowledge>> {
    return axiosClientAdmin.get(Network.category.detailDrugKnowledge + url)
  },
}
export default categoryAPI
