import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '../../hooks'
import { ResponseData } from '../../../models/types'
import Network, { API_ROOT } from '../../../apis/Network'
import {
  CreatePrescription,
  GetListPrescriptionDto,
  PrescriptionDetailType,
  PrescriptionItemType,
} from '../../../models/postPrescription'

const prescriptionApi = createApi({
  reducerPath: 'prescriptionApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getListPrescription: build.mutation<ResponseData<PrescriptionItemType[]> | undefined, unknown>({
      query: (params: GetListPrescriptionDto) => ({
        url: Network.prescription.getList,
        method: 'POST',
        data: params,
      }),
    }),
    getPrescription: build.query<PrescriptionDetailType | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.prescription.getItem + '/' + id,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<PrescriptionDetailType>) => response.data,
    }),
    addPrescription: build.mutation<ResponseData<CreatePrescription> | undefined, unknown>({
      query: (params) => ({
        url: Network.prescription.createItem,
        method: 'POST',
        data: params,
      }),
    }),
    deletePrescription: build.mutation<ResponseData<boolean> | undefined, unknown>({
      query: (id: number) => ({
        url: Network.prescription.deleteItem + '/' + id,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useAddPrescriptionMutation,
  useGetListPrescriptionMutation,
  useDeletePrescriptionMutation,
  useGetPrescriptionQuery,
} = prescriptionApi

export default prescriptionApi
