import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import {
  HomeSearchDto,
  HomeSearchResult,
  HomeSearchSuggestType,
  SearchHistory,
} from '../../../models/homeSearch/homeSearchType'

const homeSearchApi = createApi({
  reducerPath: 'homeSearchApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getSuggestSearch: build.query<HomeSearchSuggestType | undefined, unknown>({
      query: () => ({
        url: Network.homeSearch.getSuggest,
        method: 'GET',
      }),
      transformResponse: (rawData: ResponseData<HomeSearchSuggestType>) => {
        return rawData.data
      },
    }),
    homeSearch: build.mutation<HomeSearchResult | undefined, unknown>({
      query: (params: HomeSearchDto) => ({
        url: Network.homeSearch.homeSearch,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawData: ResponseData<HomeSearchResult>) => {
        return rawData.data
      },
    }),
    homeSearchHistory: build.query<SearchHistory[] | undefined, unknown>({
      query: () => ({
        url: Network.homeSearch.getHistorySearch,
        method: 'GET',
      }),
      transformResponse: (rawData: ResponseData<SearchHistory[]>) => {
        return rawData.data
      },
    }),
  }),
})

export const { useGetSuggestSearchQuery, useHomeSearchHistoryQuery, useHomeSearchMutation } = homeSearchApi

export default homeSearchApi
