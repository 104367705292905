import { RequiredVerify } from './IVerify'

const verify = (verifies: RequiredVerify[]): RequiredVerify | undefined => {
  if (!verifies || verifies.length <= 0) return undefined
  for (let i = 0; i < verifies.length; i++) {
    const result = verifies[i]
    if (!result.validate()) {
      return result
    }
  }
  return undefined
}

export default verify

export const minLengthValidation = (value: string | undefined, limit: number | undefined = 3) => {
  if (value && value.length < limit) {
    return false
  }

  return true
}
