import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { BriefProduct, GetCagetoryDto } from '../../../models/product'

const productDetailRTKQRApi = createApi({
  reducerPath: 'productDetailApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getCategoryList: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetCagetoryDto) => ({
        url: Network.productDetail.getCategoryList,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getProductByCategoryList: build.mutation<ResponseData<BriefProduct[]> | undefined, unknown>({
      query: (params: GetCagetoryDto) => ({
        url: Network.productDetail.getCategoryList,
        method: 'POST',
        data: params,
      }),
    }),
  }),
})

export const { useGetCategoryListMutation, useGetProductByCategoryListMutation } = productDetailRTKQRApi

export default productDetailRTKQRApi
