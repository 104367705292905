import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { DrugHistorySearchType, DrugType, SearchDrugDto } from '../../../models/searchDug/searchDug'

const searchDugApi = createApi({
  reducerPath: 'searchDugApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    searchDug: build.mutation<ResponseData<DrugType[]> | undefined, unknown>({
      query: (params: SearchDrugDto) => ({
        url: Network.searchDug.search,
        method: 'POST',
        data: params,
      }),
    }),
    getHistory: build.query<DrugHistorySearchType[] | undefined, unknown>({
      query: () => ({
        url: Network.searchDug.historySearch,
        method: 'GET',
      }),
      transformResponse: (res: ResponseData<DrugHistorySearchType[]>) => res.data,
    }),
    getBanner: build.query<any[] | undefined, unknown>({
      query: () => ({
        url: Network.searchDug.banners,
        method: 'GET',
      }),
      transformResponse: (res: ResponseData<any[]>) => res.data,
    }),
  }),
})

export const { useSearchDugMutation, useGetHistoryQuery, useGetBannerQuery } = searchDugApi

export default searchDugApi
