import { combineReducers } from '@reduxjs/toolkit'
import { StoreReducer } from './slice'
import { ProductDetailReducer } from '../pages/productDetailScreen/store/slice'
import { UserAccountReducer } from '../pages/userAccount/store/slice'
import { UserProductReducer } from '../pages/userProduct/store/slice'
import { UserReviewReducer } from '../pages/userReview/store/slice'
import { UserNotifyReducer } from '../pages/userNotification/store/slice'
import { UserRegularBuyReducer } from '../pages/userRegularBuy/store/slice'
import { UserPaymentMethodReducer } from '../pages/userPaymentMethod/store/slice'
import { UserOrderReducer } from '../pages/userOrder/store/slice'
import { UserOrderDetailReducer } from '../pages/userOrderDetail/store/slice'
import { SearchDrugReducer } from '../pages/drugSearch/store/slice'
import CategoryDrugKnowledgeSlice from './features/categoryDrugKnowledge/categoryDrugKnowledge-slice'
import { NewsDetailReducer } from '../pages/newsDetail/store/slice'
// import { PrescriptionReducer } from '../pages/prescription/store/slice'
import AutSlice from './features/auth/auth-slice'
import BrandSlice from './features/brand/brand-slice'
import { UserProductWishListReducer } from '../pages/userProduct/needToBuyCategory/store/slice'
import { FastCartReducer } from '../components/fastCart/store/slice'
import { VoucherPageCartReducer } from '../components/voucher/store/slice'
import { CreateOrderReducer } from '../pages/checkoutScreen/store/slice'
import { ProductListReducer } from './features/productList/product-list-slice'
import commonApi from './features/common'
import searchDugApi from './features/searchDrug'
import { PostPrescriptionReducer } from '../pages/prescription/store/slice'
import prescriptionApi from './features/prescription'
import { HomePageReducer } from '../pages/home/store/slice'
import postApi from './features/post'
import homeSearchApi from './features/homeSearch'
import brandApi from './features/brand'
import productListApi from './features/productList'
import homeApi from './features/home'
import productDetailRTKQRApi from './features/productDetail'
import categoryApi from './features/category'
import voucherApi from './features/voucher'
import pointApi from './features/point'
import orderApi from './features/order'
import userAddressApi from './features/userAddress'
import userNotifyApi from './features/userNotify'
import settingApi from './features/setting'
import commentApi from './features/comment'
import regularScheduleApi from './features/regularSchedule'
import bankApi from './features/bank'
import userReviewApi from './features/userReview'
import userFavoriteApi from './features/userProduct/userProductFavorite'
import userSeenListApi from './features/userProduct/userProductSeen'
import userBuyWithDealListApi from './features/userProduct/buyWithDeal'
import popupApi from './features/popup'

const rootReducer = combineReducers({
  store: StoreReducer,
  productDetail: ProductDetailReducer,
  userAccountPage: UserAccountReducer,
  userProductPage: UserProductReducer,
  userReviewPage: UserReviewReducer,
  userNotifyPage: UserNotifyReducer,
  userRegularBuyPage: UserRegularBuyReducer,
  userPaymentMethodPage: UserPaymentMethodReducer,
  userOrderPage: UserOrderReducer,
  userOrderDetailPage: UserOrderDetailReducer,
  searchDrugPage: SearchDrugReducer,
  drugKnowledge: CategoryDrugKnowledgeSlice,
  newsDetailPage: NewsDetailReducer,
  postPrescriptionPage: PostPrescriptionReducer,
  // prescriptionPage: PrescriptionReducer,
  auth: AutSlice,
  brand: BrandSlice,
  userProductWishList: UserProductWishListReducer,
  fastCart: FastCartReducer,
  voucherPageCart: VoucherPageCartReducer,
  createOrderPage: CreateOrderReducer,
  productListSlice: ProductListReducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [searchDugApi.reducerPath]: searchDugApi.reducer,
  [prescriptionApi.reducerPath]: prescriptionApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [homeSearchApi.reducerPath]: homeSearchApi.reducer,
  [brandApi.reducerPath]: brandApi.reducer,
  [productListApi.reducerPath]: productListApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [productDetailRTKQRApi.reducerPath]: productDetailRTKQRApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [voucherApi.reducerPath]: voucherApi.reducer,
  [pointApi.reducerPath]: pointApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [userAddressApi.reducerPath]: userAddressApi.reducer,
  [userNotifyApi.reducerPath]: userNotifyApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
  [regularScheduleApi.reducerPath]: regularScheduleApi.reducer,
  [bankApi.reducerPath]: bankApi.reducer,
  [userReviewApi.reducerPath]: userReviewApi.reducer,
  [userFavoriteApi.reducerPath]: userFavoriteApi.reducer,
  [userSeenListApi.reducerPath]: userSeenListApi.reducer,
  [userBuyWithDealListApi.reducerPath]: userBuyWithDealListApi.reducer,
  [popupApi.reducerPath]: popupApi.reducer,
  homePage: HomePageReducer,
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer
