import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../../assets/scss/fastCart.scss'
// import ic_transfer from '../../assets/icons/DetailProductScreen/ic_transfer.svg'
import FastCartItem from './FastCartItem'
// import { ReactComponent as Check } from '../../assets/icons/fontAwaresome/check.svg'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../app/hooks'
import { formatPriceVND } from '../../helpers/formatPrice'
import { selectCartData, selectListProductCollapse } from '../fastCart/store/slice'

const FastAccessCart: React.FC = () => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const location = useLocation()
  const classShow = visible ? 'main-layout__cart show' : 'main-layout__cart'
  const cartData = useAppSelector(selectCartData)
  const totalPrice = cartData?.amount ?? 0
  const listProductCollapse = useAppSelector(selectListProductCollapse)

  // caculate shipping information

  useEffect(() => {
    if (location.pathname.startsWith('/product')) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [location])
  return (
    <div className={classShow}>
      <div className='fast-cart'>
        <p className='fast-cart__total'>
          {t('fastAccessCart.provisional')} <span>{formatPriceVND(totalPrice)}</span>
        </p>
        {/* <p className='fast-cart__ship-fee fast-cart__ship-fee--full'>
          {t('fastAccessCart.shippingFee')} <span>{formatPriceVND(30000)}</span>
        </p>
        <p className='fast-cart__ship-fee fast-cart__ship-fee--hide'>
          <img src={ic_transfer} alt='icon-transfer' />
          <span>{formatPriceVND(130000)}</span>
        </p>
        <div className='fast-cart__promotion fast-cart__promotion--checked'>
          <div className='check-wrap'>
            <Check />
          </div>
          <p>{t('fastAccessCart.reduceShipping', { price: formatPriceK(30000) })}</p>
        </div>
        <div className='fast-cart__promotion fast-cart__promotion--normal'>
          <div className='check-wrap'>
            <Check />
          </div>
          <p>{t('fastAccessCart.freeShipping')}</p>
        </div> */}
        <div className='fast-cart__suggest'>
          <p>{t('fastAccessCart.noteFreeShip')}</p>
        </div>
        <div className='fast-cart__btn-checkout'>
          <button type='button' data-bs-toggle='modal' data-bs-target='#modalFastAccessCart'>
            {t('fastAccessCart.checkout')}
          </button>
        </div>
        <div className='fast-cart__list-item'>
          {listProductCollapse.map((item: any, index: number) => (
            <FastCartItem key={index} item={item} showFull={true} />
          ))}
        </div>
      </div>
      <div className='fast-cart-arrow show' />
    </div>
  )
}

export default FastAccessCart
