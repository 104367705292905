import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../hooks'
import { ResponseData } from '../../../../models/types'
import {
  AddItemToFavoriteDto,
  AddItemToFavoriteResponse,
  FilterType,
  GetRelatedProductDto,
  GetUserProductDto,
  UserProductItem,
} from '../../../../models/userProduct'
import Network, { API_ROOT } from '../../../../apis/Network'
import { BriefProduct } from '../../../../models/product'

const userFavoriteApi = createApi({
  reducerPath: 'userFavoriteApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Favorite'],
  endpoints: (build) => ({
    getFavoriteList: build.mutation<ResponseData<UserProductItem[]> | undefined, unknown>({
      query: (params: GetUserProductDto) => ({
        url: Network.userProductFavoriteList.getList,
        method: 'post',
        data: params,
      }),
    }),
    addFavoriteItem: build.mutation<ResponseData<AddItemToFavoriteResponse> | undefined, unknown>({
      query: (param: AddItemToFavoriteDto) => ({
        url: Network.userProductFavoriteList.addItem,
        method: 'POST',
        data: param,
      }),
      invalidatesTags: ['Favorite'],
    }),
    deleteFavoriteItem: build.mutation<ResponseData<number> | undefined, unknown>({
      query: (param: AddItemToFavoriteDto) => ({
        url: Network.userProductFavoriteList.deleteItem,
        method: 'delete',
        data: param,
      }),
      invalidatesTags: ['Favorite'],
    }),
    getFavoriteFilter: build.mutation<FilterType | undefined, unknown>({
      query: () => ({
        url: Network.userProductFavoriteList.getFilters,
        method: 'post',
        data: { type: 'favorite' },
      }),
      transformResponse: (rawResponse: ResponseData<FilterType>) => {
        return rawResponse.data
      },
    }),
    getFavoriteRelatedProduct: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetRelatedProductDto) => ({
        url: Network.userProductFavoriteList.getRelatedProducts,
        method: 'post',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
  }),
})

export const {
  useAddFavoriteItemMutation,
  useDeleteFavoriteItemMutation,
  useGetFavoriteListMutation,
  useGetFavoriteFilterMutation,
  useGetFavoriteRelatedProductMutation,
} = userFavoriteApi

export default userFavoriteApi
