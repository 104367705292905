import { createAsyncThunk } from '@reduxjs/toolkit'
import commonAPI from '../apis/api/api-common'

export const getCityList = createAsyncThunk('/common/getCityList', async () => {
  return await commonAPI.getCityList()
})

export const getCity = createAsyncThunk('/common/getCity', async (id: number | string) => {
  return await commonAPI.getCity(id)
})

export const getDistrictList = createAsyncThunk('/common/getDistrictList', async (id: number | string) => {
  return await commonAPI.getDistrictList(id)
})

export const getWardList = createAsyncThunk('/common/getWardList', async (id: number | string) => {
  return await commonAPI.getWardList(id)
})
