import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { clearVerifyState, verifyMail, setUserEmail, setVerifyType, next } from '../../../app/features/auth'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authStore } from '../../../app/use-selector'
import ic_mail from '../../../assets/icons/Auth/ic_mail.svg'
import { toastSuccessErr } from '../../../helpers/toast'
import { RequiredVerify } from '../../../helpers/validate/IVerify'
import verify from '../../../helpers/validate/VerifyManager'
import { LinkText } from '../../common/LinkText'
import { ROUTER } from '../../../router/config'
import { regex } from '../../../helpers/ultis'
interface Props {
  changeRender: () => void
  verify_type: string
}
function SendMail(Props: Props) {
  const auth = useAppSelector(authStore)
  const { changeRender, verify_type } = Props
  const { t } = useTranslation()
  const [email, setEmail] = useState<string>('')
  const dispatch = useAppDispatch()
  const hanldeSendMail = () => {
    const arr = [new RequiredVerify(email, t('requiredEmailPhone'), 'email')]
    const validate = verify(arr)
    if (validate) {
      toastSuccessErr({ type: 'error', message: validate.message })
      return
    }
    const param = {
      type: email.trim().match(regex.phoneNumberRegExp) ? 'phone' : 'email',
      identity: email.trim(),
      verify_type: verify_type,
    }
    dispatch(verifyMail(param))
  }

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      hanldeSendMail()
    }
  }

  useEffect(() => {
    if (auth.verify?.error) {
      dispatch(clearVerifyState())
    } else if (auth.verify?.message) {
      dispatch(next(2))
      dispatch(setUserEmail(email))
      dispatch(setVerifyType(verify_type))
      dispatch(clearVerifyState())
    }
  }, [auth])

  return (
    <>
      <h2>{verify_type === 'reset' ? t('forgotPassword.title') : t('register.registerAccount')}</h2>

      {verify_type === 'reset' ? (
        <div className='auth-box-name'>
          <p>{t('forgotPassword.fillAccount')}</p>
          <div />
        </div>
      ) : (
        <p className='auth-sub-title'>
          {t('register.registerQuestion')} <a onClick={() => changeRender()}>{t('register.login')}</a>
        </p>
      )}

      <div className='auth-box-form'>
        <label>{t('emailPhone')}</label>
        <div className='auth-box-form__item mt-1'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_mail} alt='icon' />
            </div>
            <input
              type='text'
              placeholder={t('enterEmailPhone')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleEnter}
            />
          </div>
        </div>
        <div className='auth-box-form__btn-submit'>
          <button onClick={hanldeSendMail}>
            {verify_type === 'reset' ? t('forgotPassword.sendConfirm') : t('register.registerAccount')}
          </button>
        </div>
        <p className='auth-box-text-confirm'>
          <Trans
            i18nKey='forgotPassword.agreePolicy'
            components={{
              termLink: <LinkText to={ROUTER.TERMS_OF_SERVICE_SCREEN} title='Term Link' />,
              policyLink: <LinkText to={ROUTER.PRIVACY_POLICY_SCREEN} title='Policy Link' />,
            }}
          />
        </p>
      </div>
    </>
  )
}

export default SendMail
