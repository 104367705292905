import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../app/hooks'
import { ReactComponent as ChevronDown } from '../../assets/icons/fontAwaresome/chevron_down.svg'
import FastCartItem from './fastCartProduct/fastCartItem'
import { selectListProductCollapse } from './store/slice'
interface props {
  showFull: boolean
  setShowFullProduct: (v: boolean) => void
}
function RenderListProductCollapse(props: props) {
  const { t } = useTranslation()
  const listProductCollapse = useAppSelector(selectListProductCollapse)

  return (
    <div className='modal-cart-content__info__product'>
      <div className='modal-cart-content__info__product__list-collapse'>
        {listProductCollapse.map((item: any, index: number) => {
          return (
            <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
              <FastCartItem showFull={props.showFull} item={item} />
            </div>
          )
        })}
      </div>
      <div className='modal-cart-content__info__product__btn-more'>
        <button onClick={() => props.setShowFullProduct(true)}>
          <p>{t('fastCart.seeAll')}</p>
          <ChevronDown />
        </button>
      </div>
    </div>
  )
}

export default RenderListProductCollapse
