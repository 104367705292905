import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { NewsDetailDataType } from '../../../models/newsDetailType'
import { getNewsDetail } from './thunk'

export const slice = createSlice({
  name: 'newsDetailPage',
  initialState: {
    data: {} as NewsDetailDataType,
    otherNews: [] as Array<NewsDetailDataType>,
    loading: false,
    error: false,
  },
  reducers: {
    changeData: (state, action) => {
      state.data = action.payload
    },
    changeOtherNews: (state, action) => {
      state.otherNews = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsDetail.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getNewsDetail.fulfilled, (state, action) => {
        const { data, code } = action.payload as any
        if (code === 200) {
          state.data = data
          state.error = false
        }
        if (code != 200) {
          state.error = true
        }
        state.loading = false
      })
      .addCase(getNewsDetail.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectData = (state: RootState) => state[slice.name].data
export const selectOtherNews = (state: RootState) => state[slice.name].otherNews

export const { changeData, changeOtherNews } = slice.actions

export const NewsDetailReducer = slice.reducer
