import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import {
  AddAddressNoUserDto,
  CancelOrderDto,
  ConfirmOrderDto,
  CreateOrderDto,
  CreateOrderResponse,
  GetListOrderDto,
  OrderDetailType,
  OrderReviewDto,
  OrderType,
  ReOrderDto,
  ReturnOrderDto,
  ReturnReason,
  UpdateOrderDto,
} from '../../../models/order'
import { CartType } from '../../../models/cart'

const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Order'],
  endpoints: (build) => ({
    getOrderList: build.mutation<ResponseData<OrderType[]> | undefined, unknown>({
      query: (params: GetListOrderDto) => ({
        url: Network.order.orderList,
        method: 'POST',
        data: params,
      }),
    }),
    getOrderDetail: build.query<OrderDetailType | undefined, unknown>({
      query: (id: number) => ({
        url: Network.order.orderDetail + id,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<OrderDetailType>) => {
        return rawResult?.data
      },
      providesTags: ['Order'],
    }),
    getCreateOrderDetail: build.query<OrderDetailType | undefined, unknown>({
      query: (id: number) => ({
        url: Network.order.createOrderDetail + id,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<OrderDetailType>) => {
        return rawResult?.data
      },
      providesTags: ['Order'],
    }),
    createOrder: build.mutation<ResponseData<CreateOrderResponse> | undefined, unknown>({
      query: (params: CreateOrderDto) => ({
        url: Network.order.createOrder,
        method: 'POST',
        data: params,
      }),
    }),
    confirmOrder: build.mutation<ResponseData<boolean> | undefined, unknown>({
      query: (params: ConfirmOrderDto) => ({
        url: Network.order.confirmOrder,
        method: 'POST',
        data: params,
      }),
    }),
    buyNow: build.mutation<ResponseData<boolean> | undefined, unknown>({
      query: (params: CreateOrderDto) => ({
        url: Network.order.buyNow,
        method: 'POST',
        data: params,
      }),
    }),
    updateOrder: build.mutation<ResponseData<boolean> | undefined, unknown>({
      query: (params: UpdateOrderDto) => ({
        url: Network.order.updateOrder + params.order_id,
        method: 'PUT',
        data: params,
      }),
      invalidatesTags: ['Order'],
    }),
    updateOrderPromotion: build.mutation<[] | undefined, unknown>({
      query: (params: CartType) => ({
        url: Network.cart.updatePromotion,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Order'],
    }),
    addAddressNoUser: build.mutation<ResponseData<boolean> | undefined, unknown>({
      query: (params: AddAddressNoUserDto) => ({
        url: Network.order.addAddressNoUser,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Order'],
    }),
    cancelOrder: build.mutation<ResponseData<[]> | undefined, unknown>({
      query: (params: CancelOrderDto) => ({
        url: Network.order.cancelOrder,
        method: 'POST',
        data: params,
      }),
    }),
    returnOrder: build.mutation({
      query: (params: ReturnOrderDto) => ({
        url: Network.order.returnOrder,
        method: 'POST',
        data: params,
      }),
    }),
    getReturnReason: build.query<ReturnReason[] | undefined, unknown>({
      query: () => ({
        url: Network.order.getReturnReason,
        method: 'GET',
      }),
      transformResponse: (res: ResponseData<ReturnReason[]>) => res.data,
    }),
    reOrder: build.mutation({
      query: (params: ReOrderDto) => ({
        url: Network.order.reOrder,
        method: 'POST',
        data: params,
      }),
    }),
    reviewOrder: build.mutation<ResponseData<[]>, unknown>({
      query: (data: OrderReviewDto) => ({
        url: Network.order.reviewOrder,
        method: 'post',
        data: data,
      }),
      invalidatesTags: ['Order'],
    }),
  }),
})

export const {
  useCreateOrderMutation,
  useGetOrderDetailQuery,
  useConfirmOrderMutation,
  useBuyNowMutation,
  useUpdateOrderMutation,
  useGetOrderListMutation,
  useAddAddressNoUserMutation,
  useGetCreateOrderDetailQuery,
  useCancelOrderMutation,
  useGetReturnReasonQuery,
  useReturnOrderMutation,
  useUpdateOrderPromotionMutation,
  useReOrderMutation,
  useReviewOrderMutation,
  useLazyGetCreateOrderDetailQuery,
} = orderApi

export default orderApi
