import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { clearForgotPassState, fetchForgotPassUser } from '../../../app/features/auth'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authStore } from '../../../app/use-selector'
import ic_hide from '../../../assets/icons/Auth/ic_hide.svg'
import ic_pass from '../../../assets/icons/Auth/ic_pass.svg'
import ic_show from '../../../assets/icons/Auth/ic_show.svg'
function FormForGotPassword() {
  const auth = useAppSelector(authStore)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [showPass, setShowPass] = useState<boolean>(false)
  const [userPass, setUserPass] = useState({
    password: '',
    password_confirm: '',
  })
  const handleCreatePass = () => {
    if (userPass.password !== userPass.password_confirm) return toast.error(t('passNotMatch'))
    const param = {
      password: userPass.password.trim(),
      password_confirm: userPass.password_confirm.trim(),
      email: auth.registerStep.email.trim(),
    }

    dispatch(fetchForgotPassUser(param))
  }

  const onChange = (key: string, value?: string) => {
    setUserPass((prev) => ({ ...prev, [key]: value || '' }))
  }

  useEffect(() => {
    // Kiểm tra nếu có lỗi khi quên mật khẩu
    if (auth.forgotpass.code != 200 && auth.forgotpass?.error) {
      // Xóa trạng thái của quên mật khẩu
      dispatch(clearForgotPassState())
    }
  }, [auth.forgotpass])

  return (
    <>
      <h2>{t('forgotPassword.changePassword')}</h2>
      <div className='auth-box-name'>
        <p>{t('forgotPassword.fillPassword')}</p>
        <div />
      </div>
      <div className='auth-box-form'>
        <label>{t('forgotPassword.newPassword')}</label>
        <div className='auth-box-form__item'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_pass} alt='icon' />
            </div>
            <input
              onChange={(e) => onChange('password', e.target.value)}
              type={showPass ? 'text' : 'password'}
              placeholder={t('forgotPassword.newPasswordPlaceholder')}
              autoComplete='new-password'
            />
            <button onClick={() => setShowPass(!showPass)}>
              <img src={showPass ? ic_show : ic_hide} alt='icon' />
            </button>
          </div>
        </div>
        <label className='mt-2'>{t('forgotPassword.retypePassword')}</label>
        <div className='auth-box-form__item'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_pass} alt='icon' />
            </div>
            <input
              onChange={(e) => onChange('password_confirm', e.target.value)}
              type={showPass ? 'text' : 'password'}
              placeholder={t('forgotPassword.retypePasswordPlaceholder')}
            />
            <button onClick={() => setShowPass(!showPass)}>
              <img src={showPass ? ic_show : ic_hide} alt='icon' />
            </button>
          </div>
        </div>
        <div className='auth-box-form__btn-submit'>
          <button onClick={handleCreatePass}>{t('forgotPassword.changePassword')}</button>
        </div>
      </div>
    </>
  )
}

export default FormForGotPassword
