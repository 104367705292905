import { createAsyncThunk } from '@reduxjs/toolkit'
import categoryAPI from '../../../apis/api/api-category'

export const fetchDrugKnowledge = createAsyncThunk('/CategoryDrugKnowledge/fetchDrugKnowledge', async () => {
  return await categoryAPI.fetchDrugKnowledge()
})

export const getDetailDrugKnowledge = createAsyncThunk(
  '/CategoryDrugKnowledge/getDetailDrugKnowledge',
  async (url: string) => {
    return await categoryAPI.getDetailDrugKnowledge(url)
  },
)
