import { useTranslation } from 'react-i18next'
import login1 from '../../assets/images/OrderingGuide/login-1.png'
import arrow from '../../assets/images/OrderingGuide/arrow.png'
import img_step_1 from '../../assets/images/OrderingGuide/navLogin/step-1.png'
import img_step_2 from '../../assets/images/OrderingGuide/navLogin/step-2.png'
import img_step_2_1 from '../../assets/images/OrderingGuide/navLogin/step-2-1.png'
import img_step_2_2 from '../../assets/images/OrderingGuide/navLogin/step-2-2.png'
import img_step_2_3 from '../../assets/images/OrderingGuide/navLogin/step-2-3.png'
import img_step_2_4 from '../../assets/images/OrderingGuide/navLogin/step-2-4.png'
import img_step_2_5 from '../../assets/images/OrderingGuide/navLogin/step-2-5.png'
function NavLogin() {
  const { t } = useTranslation()
  return (
    <div className='nav-login'>
      <div className='row mb-15'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 1:</strong>
          </p>
          <div className='step-content'>
            <p>
              Tại giao diện trang chủ Omi Pharma: <br /> tìm và chọn mục ĐĂNG KÝ hoặc ĐĂNG NHẬP ở góc bên phải
            </p>
          </div>
        </div>
        <div className='col-lg-6 col-sm-6'>
          <img src={img_step_1} alt='Omi Pharma website screenshot' className='img-fluid' />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-sm-6'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 2a:</strong>
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>Cách 1:</h6>
              <p>
                Đăng nhập Omi Pharma bằng cách nhấp vào nút Đăng Nhập, sau đó nhập Email và mật khẩu của quý khách. Tiếp
                đó, nhấp vào nút Đăng nhập ở dưới để hoàn tất đăng nhập.{' '}
              </p>
            </div>

            <div className='how-to-do'>
              <h6>Cách 2:</h6>
              <p>Quý khách có thể Đăng nhập Omi Pharma bằng tài khoản Google hoặc Facebook ở dưới. </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={img_step_2} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-6 col-sm-6'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 2b:</strong>
          </p>
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content '>
            <div className='how-to-do mb-15'>
              <h6>Bước 1:</h6>
              <p>Quý khách cần nhấp vào chữ "Đăng ký tài khoản"</p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={img_step_2_1} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>Bước 2:</h6>
              <p>Tiếp đến, quý khách cần nhập mail muốn sử dụng để đăng ký tài khoản</p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={img_step_2_2} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>Bước 3:</h6>
              <p>Sau đó, quý khách sẽ nhận được 01 mã gồm 6 chữ số qua email mình đăng ký.</p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={img_step_2_3} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>Bước 4:</h6>
              <p>
                Tiến hành nhập 01 mã gồm 6 chữ số quý khách vừa nhận được qua email đã thực hiện đăng ký rồi nhấn vào
                nút xác minh tài khoản
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={img_step_2_4} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>Bước 5:</h6>
              <p>
                Tại màn hình nhập thông tin tài khoản vui lòng nhập các thông tin theo hướng dẫn sau đó kiểm tra lại
                thông tin đã điền và bấm vào nút ĐĂNG KÝ TÀI KHOẢN để kết thúc việc đăng ký. Sau khi đăng ký thành công
                sẽ có thông báo "ĐĂNG KÝ TÀI KHOẢN THÀNH CÔNG", khi đó tài khoản của quý khách đã sẵn sàng để sử dụng.
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={img_step_2_5} alt='Omi Pharma website screenshot' />
        </div>
      </div>
    </div>
  )
}

export default NavLogin
