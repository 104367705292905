import { AddressItemType } from '../../models/address'
import { ResponseData } from '../../models/types'
import { AddressItemDtoType } from '../../models/address'
import Network from '../Network'
import axiosClientAdmin from '../request'

const addressAPI = {
  getListAddress(): Promise<ResponseData<AddressItemType[]>> {
    return axiosClientAdmin.get(Network.userAddress.getList)
  },
  updateAddressItem(params: AddressItemDtoType): Promise<ResponseData<boolean>> {
    return axiosClientAdmin.put(Network.userAddress.putItem + params.id, params)
  },
  addAddressItem(params: AddressItemDtoType): Promise<ResponseData<AddressItemType>> {
    return axiosClientAdmin.post(Network.userAddress.putItem, params)
  },
  deleteAddressItem(id: string | number): Promise<ResponseData<boolean>> {
    return axiosClientAdmin.delete(Network.userAddress.deleteItem + id)
  },
}

export default addressAPI
