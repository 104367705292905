import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { SearchComment, commentType } from '../../../models/newsDetailType'

export const commentApi = createApi({
  reducerPath: 'commentAPi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    pushComment: build.mutation<ResponseData<commentType>, unknown>({
      query: (data) => ({
        url: Network.comment.comment,
        method: 'post',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<commentType>) => {
        return rawResult
      },
    }),
    getComment: build.mutation<ResponseData<commentType[]>, unknown>({
      query: (data: SearchComment) => ({
        url: Network.comment.search,
        method: 'post',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<commentType[]>) => {
        return rawResult
      },
    }),
  }),
})

export const { usePushCommentMutation, useGetCommentMutation } = commentApi

export default commentApi
