import SwitchBox from '../switchBox'
import RenderListProductFull from './renderListProductFull'
import RenderListProductCollapse from './renderListProductCollapse'
import { formatPriceVND } from '../../helpers/formatPrice'
import { useTranslation } from 'react-i18next'
import { isEmpty } from '../../helpers/ultis'
import { useEffect, useState } from 'react'
import { toastSuccessErr } from '../../helpers/toast'
import Voucher from './Voucher'

interface props {
  showFullProduct: boolean
  changeShowVou: (value: boolean) => void
  showPickerVoucher: boolean
  setShowFullProduct: (v: boolean) => void
  addedPoint: number
  usedPoints: number
  userInfo: any
  omiPoint: number
  totalPriceTemp: number
  discount: number
  updateUsePoint: any
  cartData: any
  totalQuantity: number
}
function FastCartContentInfo(props: props) {
  const { t } = useTranslation()
  const [chooseOmiPoint, setChooseOmiPoint] = useState<boolean>(!!props.usedPoints)

  useEffect(() => {
    if (!props.totalPriceTemp) {
      setChooseOmiPoint(false)
      props.updateUsePoint(0)
    } else {
      setChooseOmiPoint(!!props.usedPoints)
    }
  }, [props.usedPoints, props.totalPriceTemp])

  const handleToggleSwitch = () => {
    if (!props.totalPriceTemp) {
      toastSuccessErr({ type: 'error', message: t('fastCart.noChooseItem') })
      return
    }
    setChooseOmiPoint(!chooseOmiPoint)
    if (!chooseOmiPoint) {
      props.updateUsePoint(props.omiPoint)
    } else {
      props.updateUsePoint(0)
    }
  }
  return (
    <div className='modal-cart-content__info'>
      {/* <div className='modal-cart-content__info__suggest-box'>
        <p>
          {t('fastCart.buyMore')} <span className='suggest-box__text--highlight'>{formatPriceVND(20000)}</span>{' '}
          {t('fastCart.freeship2')}
          <span className='suggest-box__text--danger'>({t('fastCart.donotappply')})</span>
        </p>
      </div> */}
      {props.showFullProduct ? (
        <RenderListProductFull setShowFullProduct={props.setShowFullProduct} />
      ) : (
        <RenderListProductCollapse setShowFullProduct={props.setShowFullProduct} showFull={false} />
      )}
      <div className='modal-cart-content__info__voucher'>
        <p className='modal-cart-content__info__voucher__label'>{t('fastCart.voucher')}</p>
        <Voucher totalItem={props.totalPriceTemp} cartData={props.cartData} />
      </div>
      {!isEmpty(props.userInfo) && (
        <div className='modal-cart-content__info__point'>
          <p className='modal-cart-content__info__point__label'>{t('fastCart.myOmiPoint')}</p>
          <div className='modal-cart-content__info__point__switch-btn'>
            <p>
              [{props.omiPoint} {t('fastCart.point')}]
            </p>
            <SwitchBox checked={chooseOmiPoint} onchange={handleToggleSwitch} />
          </div>
        </div>
      )}
      <div className='modal-cart-content__info__cost'>
        <div className='d-flex flex-column'>
          <span>
            {t('fastCart.provisional')} ({props.totalQuantity} {t('fastCart.product')})
          </span>
          <span>{t('fastCart.discount')}</span>
          {!isEmpty(props.userInfo) && (
            <>
              <span>{t('fastCart.usedPoint')}</span>
              <span>{t('fastCart.addedPointLabel')}</span>
            </>
          )}
        </div>
        <div className='d-flex flex-column align-items-end'>
          <span>{formatPriceVND(props.totalPriceTemp)}</span>
          <span>{formatPriceVND(-props.discount)}</span>
          {!isEmpty(props.userInfo) && (
            <>
              <span>-{props.usedPoints}</span>
              <span>+{props.addedPoint}</span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FastCartContentInfo
