import { axiosBaseQuery } from '../../hooks'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  CancelReturnReason,
  CityType,
  DistrictType,
  MedicineItemType,
  ResponseData,
  ReviewSuggest,
  SearchMedicineDto,
  StoreType,
  WardType,
  paramSearchShop,
} from '../../../models/types'
import Network, { API_ROOT } from '../../../apis/Network'

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getStoreList: build.query<StoreType[] | undefined, unknown>({
      query: () => ({
        url: Network.store.getList,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<StoreType[]>) => {
        const data = rawResult.data
        return data
      },
    }),
    searchStoreList: build.mutation<StoreType[] | undefined, unknown>({
      query: (prams: paramSearchShop) => ({
        url: Network.store.searchList,
        method: 'post',
        data: {
          filter: {
            ...prams,
          },
          pageSize: 15,
        },
      }),
      transformResponse: (rawResult: ResponseData<StoreType[]>) => {
        const data = rawResult.data
        return data
      },
    }),
    getCityList: build.query<CityType[] | undefined, unknown>({
      query: () => ({
        url: Network.common.city.getList,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<CityType[]>) => {
        const data = rawResult.data
        return data
      },
    }),
    getCity: build.query<CityType | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.common.city.getItem(id),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<CityType>) => {
        return rawResult.data
      },
    }),
    getDistrictList: build.query<DistrictType[] | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.common.district.getList,
        method: 'post',
        data: {
          filter: {
            province_id: { equal: id },
          },
          pageSize: 999,
        },
      }),
      transformResponse: (rawResult: ResponseData<DistrictType[]>) => {
        return rawResult.data
      },
    }),
    getDistrict: build.query<DistrictType | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.common.district.getItem(id),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<DistrictType>) => {
        return rawResult.data
      },
    }),
    getWardList: build.query<WardType[] | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.common.ward.getList,
        method: 'post',
        data: {
          filter: {
            district_id: { equal: id },
          },
          pageSize: 999,
        },
      }),
      transformResponse: (rawResult: ResponseData<WardType[]>) => {
        return rawResult.data
      },
    }),
    getWard: build.query<WardType | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.common.ward.getItem(id),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<WardType>) => {
        return rawResult.data
      },
    }),
    searchMedicineList: build.mutation<MedicineItemType[] | undefined, unknown>({
      query: (param: SearchMedicineDto) => ({
        url: Network.medicine.searchMedicine,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<MedicineItemType[]>) => {
        return rawResult.data
      },
    }),
    getCancelSuggestReason: build.query<CancelReturnReason[] | undefined, unknown>({
      query: () => ({
        url: Network.common.cancelSuggestReason,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<CancelReturnReason[]>) => {
        return rawResult.data
      },
    }),
    getReturnSuggestReason: build.query<CancelReturnReason[] | undefined, unknown>({
      query: () => ({
        url: Network.common.returnSuggestReason,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<CancelReturnReason[]>) => {
        return rawResult.data
      },
    }),
    getProductReviewSuggest: build.query<ReviewSuggest[] | undefined, unknown>({
      query: () => ({
        url: Network.common.reviewSuggest,
        method: 'get',
      }),
      transformResponse: (raw: ResponseData<ReviewSuggest[]>) => raw.data,
    }),
  }),
})

export const {
  useGetStoreListQuery,
  useGetCityListQuery,
  useGetCityQuery,
  useGetDistrictListQuery,
  useGetDistrictQuery,
  useGetWardListQuery,
  useGetWardQuery,
  useSearchMedicineListMutation,
  useSearchStoreListMutation,
  useGetCancelSuggestReasonQuery,
  useGetReturnSuggestReasonQuery,
  useGetProductReviewSuggestQuery,
} = commonApi

export default commonApi
