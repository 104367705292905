import { Trans, useTranslation } from 'react-i18next'
import ic_company from '../../../assets/icons/Auth/ic_company.svg'
import ic_hide from '../../../assets/icons/Auth/ic_hide.svg'
import ic_name from '../../../assets/icons/Auth/ic_name.svg'
import ic_pass from '../../../assets/icons/Auth/ic_pass.svg'
import ic_show from '../../../assets/icons/Auth/ic_show.svg'
import { ReactComponent as Check } from '../../../assets/icons/fontAwaresome/check.svg'
import { useState } from 'react'
import { LinkText } from '../../common/LinkText'
import { FiMail, FiPhoneCall } from 'react-icons/fi'
import { BsGenderAmbiguous } from 'react-icons/bs'
import { FaBirthdayCake } from 'react-icons/fa'
import { authStore } from '../../../app/use-selector'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { toastSuccessErr } from '../../../helpers/toast'
import { fetchRegister } from '../../../app/features/auth'
import { RequiredVerify } from '../../../helpers/validate/IVerify'
import verify from '../../../helpers/validate/VerifyManager'
import { ROUTER } from '../../../router/config'
import { regex } from '../../../helpers/ultis'

interface Props {
  changeRender: () => void
}

function FormRegister(props: Props) {
  const dispatch = useAppDispatch()
  const auth = useAppSelector(authStore)
  const { t } = useTranslation()
  const [value, setValue] = useState({
    name: '',
    birthday: '',
    phone: '',
    password: '',
    gender: 1,
    partner_code: '',
    email: '',
  })
  const [showPass, setShowPass] = useState<boolean>(false)
  //   const [showRetypePass, setShowRetypePass] = useState<boolean>(false)
  const [agreePolicy, setAgreePolicy] = useState<boolean>(false)
  const { changeRender } = props
  const phone = auth.registerStep.email.match(regex.phoneNumberRegExp) ? auth.registerStep.email : ''
  const email = !auth.registerStep.email.match(regex.phoneNumberRegExp) ? auth.registerStep.email : ''

  const onChange = (key: string, value?: string) => {
    setValue((prev) => ({ ...prev, [key]: value || '' }))
  }
  const register = () => {
    if (!agreePolicy) return toastSuccessErr({ type: 'warning', message: t('errorTermPolicy') })
    let arr = [new RequiredVerify(value.phone, t('requirePhone'), 'phone')]
    if (phone) {
      arr = [new RequiredVerify(value.email, t('requiredEmail'), 'email')]
    }
    const validate = verify(arr)
    if (validate) {
      toastSuccessErr({ type: 'error', message: validate.message })
      return
    }

    const param = {
      name: value.name.trim(),
      birthday: value.birthday.trim(),
      phone: phone ? phone : value.phone.trim(),
      password: value.password.trim(),
      partner_code: value.partner_code.trim(),
      gender: value.gender,
      email: email ? email : value.email.trim(),
    }
    dispatch(fetchRegister(param))
  }
  return (
    <>
      <h2>{t('register.registerAccount')}</h2>
      <p className='auth-sub-title'>
        {t('register.registerQuestion')} <a onClick={() => changeRender()}>{t('register.login')}</a>
      </p>
      <div className='auth-box-name'>
        <p>{t('register.fillInfo')}</p>
        <div />
      </div>
      <div className='auth-box-form'>
        <label className='mt-2'>{t('register.loginName')}</label>
        <div className='auth-box-form__item mt-1'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_name} alt='icon' />
            </div>
            <input
              type='text'
              placeholder={t('register.loginNamePlaceholder')}
              onChange={(e) => onChange('name', e.target.value)}
            />
          </div>
        </div>
        <label className='mt-2'>{t('gender')}</label>
        <div className='auth-box-form__item mt-1'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <BsGenderAmbiguous color='gray' size={20} />
            </div>
            <select
              className='select-register'
              value={value.gender}
              onChange={(e) => onChange('gender', e.target.value)}
            >
              <option value='2'>{t('female')}</option>
              <option value='1'>{t('male')}</option>
            </select>
          </div>
        </div>
        <label className='mt-2'>{t('birthday')}</label>
        <div className='auth-box-form__item mt-1'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <FaBirthdayCake color='gray' size={20} />
            </div>
            <input
              id='datepicker'
              className='input-login-form'
              type='date'
              name='birthday'
              onChange={(e) => onChange('birthday', e.target.value)}
              value={value.birthday}
              placeholder={t('birthday')}
            />
          </div>
        </div>
        {email && (
          <>
            <label className='mt-2'>{t('phone')}</label>
            <div className='auth-box-form__item mt-1'>
              <div className='auth-box-form__input'>
                <div className='auth-box-form__input__icon'>
                  <FiPhoneCall color='gray' size={20} />
                </div>
                <input
                  id='phone'
                  name='phone'
                  type='text'
                  pattern='\d*'
                  placeholder={t('phone')}
                  onChange={(e) => onChange('phone', e.target.value)}
                  value={value.phone}
                  autoComplete='off'
                />
              </div>
            </div>
          </>
        )}
        {phone && (
          <>
            <label className='mt-2'>{t('email')}</label>
            <div className='auth-box-form__item mt-1'>
              <div className='auth-box-form__input'>
                <div className='auth-box-form__input__icon'>
                  <FiMail color='gray' size={20} />
                </div>
                <input
                  id='email'
                  name='email'
                  type='text'
                  pattern='\d*'
                  placeholder={t('email')}
                  onChange={(e) => onChange('email', e.target.value)}
                  value={value.email}
                  autoComplete='off'
                />
              </div>
            </div>
          </>
        )}
        <label className='mt-2'>{t('register.password')}</label>
        <div className='auth-box-form__item mt-1'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_pass} alt='icon' />
            </div>
            <input
              type={showPass ? 'text' : 'password'}
              placeholder={t('register.password')}
              onChange={(e) => onChange('password', e.target.value)}
              autoComplete='new-password'
            />
            <button onClick={() => setShowPass(!showPass)}>
              <img src={showPass ? ic_show : ic_hide} alt='icon' style={{ width: '20px' }} />
            </button>
          </div>
        </div>
        {/* <label className='mt-2'>{t('register.retypePassword')}</label>
        <div className='auth-box-form__item mt-1'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_pass} alt='icon' />
            </div>
            <input type={showRetypePass ? 'text' : 'password'} placeholder={t('register.retypePasswordPlaceholder')} />
            <button onClick={() => setShowRetypePass(!showRetypePass)}>
              <img src={showRetypePass ? ic_show : ic_hide} alt='icon' />
            </button>
          </div>
        </div> */}
        <label className='mt-2'>{t('register.companyCode')}</label>
        <div className='auth-box-form__item mt-1'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_company} alt='icon' />
            </div>
            <input
              type='text'
              placeholder={t('register.companyCodePlaceholder')}
              onChange={(e) => onChange('partner_code', e.target.value)}
            />
          </div>
        </div>
        <div className='auth-box-form__option'>
          <div className='auth-box-form__option__check'>
            <div onClick={() => setAgreePolicy(!agreePolicy)} className={`check-radio ${agreePolicy ? 'active' : ''} `}>
              <Check />
            </div>
            <p>
              <Trans
                i18nKey='register.agreePolicy'
                components={{
                  termLink: <LinkText to={ROUTER.TERMS_OF_SERVICE_SCREEN} title='Term Link' />,
                  policyLink: <LinkText to={ROUTER.PRIVACY_POLICY_SCREEN} title='Policy Link' />,
                }}
              />
            </p>
          </div>
        </div>
        <div className='auth-box-form__btn-submit'>
          <button onClick={register}>{t('register.registerAccount')}</button>
        </div>
      </div>
    </>
  )
}

export default FormRegister
