import React, { useEffect } from 'react'
import './loading.scss'
function LoadingCpn() {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.body.style.height = '100%'
    return () => {
      document.body.style.overflow = 'auto'
      document.body.style.height = 'auto'
    }
  }, [])

  return (
    <div className='loader-container'>
      <div className='spinner'></div>
    </div>
  )
}

export default LoadingCpn
