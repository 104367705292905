import { createApi } from '@reduxjs/toolkit/query/react'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { ConsultReview, ProductOrderReview, ShippingReview } from '../../../models/userReview'
import { axiosBaseQuery } from '../../hooks'

const userReviewApi = createApi({
  reducerPath: 'userReviewApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getShippingReview: build.mutation<ResponseData<ShippingReview[]> | undefined, unknown>({
      query: (params) => ({
        url: Network.userReview.shippingReview,
        method: 'POST',
        data: params,
      }),
    }),
    getProductOrderReview: build.mutation<ResponseData<ProductOrderReview[]> | undefined, unknown>({
      query: (params) => ({
        url: Network.userReview.productReview,
        method: 'POST',
        data: params,
      }),
    }),
    getConsultReview: build.mutation<ResponseData<ConsultReview[]> | undefined, unknown>({
      query: (params) => ({
        url: Network.userReview.consultReview,
        method: 'POST',
        data: params,
      }),
    }),
    deleteConsultReview: build.mutation<boolean | undefined, unknown>({
      query: (id: number) => ({
        url: Network.userReview.deleteConsultReview + id,
        method: 'DELETE',
      }),
    }),
    deleteShippingReview: build.mutation<boolean | undefined, unknown>({
      query: (id: number) => ({
        url: Network.userReview.deleteShippingReview + id,
        method: 'DELETE',
      }),
    }),
    deleteProductReview: build.mutation<boolean | undefined, unknown>({
      query: (id: number) => ({
        url: Network.userReview.deleteProductReview + id,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetConsultReviewMutation,
  useGetShippingReviewMutation,
  useGetProductOrderReviewMutation,
  useDeleteConsultReviewMutation,
  useDeleteShippingReviewMutation,
  useDeleteProductReviewMutation,
} = userReviewApi

export default userReviewApi
