import { toast } from 'react-toastify'
import i18n from '../i18n'
export const toastSuccessErr = (props: { message?: string; type?: 'error' | 'success' | 'warning' }) => {
  const { message, type } = props
  switch (type) {
    case 'error':
      return toast.error(message ? message : i18n.t('error'), {
        theme: 'colored',
      })
    case 'warning':
      return toast.warning(message ? message : i18n.t('warning'), {
        theme: 'colored',
      })
    default:
      return toast.success(message ? message : i18n.t('actionSuccess'), {
        theme: 'colored',
      })
  }
}
