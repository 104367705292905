import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProductDetailType } from '../../../models/types'

export const slice = createSlice({
  name: 'searchDrugPage',
  initialState: {
    historySearch: [] as any,
    productList: [] as ProductDetailType[],
    initData: {} as any,
  },
  reducers: {
    changeProductList: (state, action) => {
      state.productList = action.payload
    },
    changeInitData: (state, action) => {
      state.initData = action.payload
    },
  },
})

export const selectProductList = (state: RootState) => state[slice.name].productList
export const selectInitData = (state: RootState) => state[slice.name].initData

export const { changeProductList, changeInitData } = slice.actions

export const SearchDrugReducer = slice.reducer
