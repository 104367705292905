import { lazy } from 'react'
import { ROUTER, RouterProps } from './config'
import OrderingGuideScreen from '../pages/orderingGuideScreen'

const HomeScreen = lazy(() => import('../pages/home'))
const ProductDetailScreen = lazy(() => import('../pages/productDetailScreen'))
const CheckoutScreen = lazy(() => import('../pages/checkoutScreen'))
const UserScreen = lazy(() => import('../pages/user'))
const UserAccountScreen = lazy(() => import('../pages/userAccount'))
const UserProductScreen = lazy(() => import('../pages/userProduct'))
const UserReviewScreen = lazy(() => import('../pages/userReview'))
const UserNotificationScreen = lazy(() => import('../pages/userNotification'))
const UserRegularBuyScreen = lazy(() => import('../pages/userRegularBuy'))
const UserPaymentMethodScreen = lazy(() => import('../pages/userPaymentMethod'))
const UserOrderScreen = lazy(() => import('../pages/userOrder'))
const UseOrderDetailScreen = lazy(() => import('../pages/userOrderDetail'))
const UserVoucherScreen = lazy(() => import('../pages/userVoucher'))
const PersonalPointScreen = lazy(() => import('../pages/personalPointScreen'))
const BrandScreen = lazy(() => import('../pages/branding'))
const BrandDetailScreen = lazy(() => import('../pages/brandDetail'))
const SearchScreen = lazy(() => import('../pages/search'))
const SearchDrugScreen = lazy(() => import('../pages/drugSearch'))
const CategoryScreen = lazy(() => import('../pages/categoryScreen'))
const PolicyScreen = lazy(() => import('../pages/policy'))
const NewsScreen = lazy(() => import('../pages/news'))
const DrugKnowledgeScreen = lazy(() => import('../pages/drugKnowledge'))
const DrugKnowledgeDetailScreen = lazy(() => import('../pages/drugKnowledge/detail'))
const NewsDetailScreen = lazy(() => import('../pages/newsDetail'))
const PrescriptionScreen = lazy(() => import('../pages/prescription'))
const ManagePrescriptionsScreen = lazy(() => import('../pages/prescription/managePrescriptions'))
const HealthyScreen = lazy(() => import('../pages/healthyScreen'))
const HealthCategory = lazy(() => import('../pages/healthCategory'))
const HealthDetail = lazy(() => import('../pages/healthDetail'))
const PromotionScreen = lazy(() => import('../pages/promotion'))
const ListStoreScreen = lazy(() => import('../pages/listStorePharma'))
const DeliveryPolicyScreen = lazy(() => import('../pages/deliveryPolicyScreen'))
const TermsOfServiceScreen = lazy(() => import('../pages/termsOfServiceScreen'))
const ReturnPolicyScreen = lazy(() => import('../pages/returnPolicyScreen'))
const PrivacyPolicyScreen = lazy(() => import('../pages/privacyPolicyScreen'))
// const OrderingGuideScreen = lazy(() => import('../pages/orderingGuideScreen'))
const OmiPointScreen = lazy(() => import('../pages/omiPointScreen'))
const IntroOmiPharmaScreen = lazy(() => import('../pages/introOmiPharmaScreen'))
const PreviewProduct = lazy(() => import('../pages/preview/product'))
const PreviewNews = lazy(() => import('../pages/preview/newsDetail'))
const PreviewHealth = lazy(() => import('../pages/preview/healthDetail'))
const RegularBuyConfirmScreen = lazy(() => import('../pages/regularBuyConfirm'))

export const ROUTERS: RouterProps[] = [
  {
    path: ROUTER.HOME_PAGE,
    element: HomeScreen,
  },
  {
    path: ROUTER.PRODUCT_DETAIL_SCREEN,
    element: ProductDetailScreen,
  },
  {
    path: ROUTER.CREATE_ORDER,
    element: CheckoutScreen,
  },
  {
    path: ROUTER.USER_SCREEN,
    element: UserScreen,
  },
  {
    path: ROUTER.BRAND_SCREEN,
    element: BrandScreen,
  },
  {
    path: ROUTER.BRAND_SCREEN_DETAIL,
    element: BrandDetailScreen,
  },
  {
    path: ROUTER.SEARCH_SCREEN,
    element: SearchScreen,
  },
  {
    path: ROUTER.DRUG_SEARCH_SCREEN,
    element: SearchDrugScreen,
  },
  {
    path: ROUTER.CATEGORY_SCREEN,
    element: CategoryScreen,
  },
  {
    path: ROUTER.DRUG_KNOWLEDGE_SCREEN,
    element: DrugKnowledgeScreen,
  },
  {
    path: ROUTER.DRUG_KNOWLEDGE_DETAIL_SCREEN,
    element: DrugKnowledgeDetailScreen,
  },
  {
    path: ROUTER.POLICY_SCREEN,
    element: PolicyScreen,
    children: [
      {
        path: ROUTER.ORDERING_GUIDE_SCREEN,
        element: OrderingGuideScreen,
      },
      {
        path: ROUTER.DELIVERY_POLICY_SCREEN,
        element: DeliveryPolicyScreen,
      },
      {
        path: ROUTER.RETURN_POLICY_SCREEN,
        element: ReturnPolicyScreen,
      },
      {
        path: ROUTER.PRIVACY_POLICY_SCREEN,
        element: PrivacyPolicyScreen,
      },
      {
        path: ROUTER.TERMS_OF_SERVICE_SCREEN,
        element: TermsOfServiceScreen,
      },
      {
        path: ROUTER.OMI_POINT_SCREEN,
        element: OmiPointScreen,
      },
    ],
  },
  {
    path: ROUTER.POLICY_TAB_SCREEN,
    element: PolicyScreen,
  },
  {
    path: ROUTER.NEWS_SCREEN,
    element: NewsScreen,
  },
  {
    path: ROUTER.NEWS_DETAIL_SCREEN,
    element: NewsDetailScreen,
  },
  {
    path: ROUTER.NEWS_REVIEW_SCREEN,
    element: NewsScreen,
    props: {
      type: 4,
    },
  },
  {
    path: ROUTER.NEWS_REVIEW_DETAIL_SCREEN,
    element: NewsDetailScreen,
    props: {
      type: 4,
    },
  },
  {
    path: ROUTER.PRESCRIPTION_SCREEN,
    element: PrescriptionScreen,
  },
  {
    path: ROUTER.MANAGE_PRESCRIPTION_SCREEN,
    element: ManagePrescriptionsScreen,
  },
  {
    path: ROUTER.HEALTH,
    element: HealthyScreen,
  },
  {
    path: ROUTER.HEALTH_CATEGORY,
    element: HealthCategory,
  },
  {
    path: ROUTER.HEALTH_DETAIL,
    element: HealthDetail,
    props: {
      type: 3, //Sống khẻ
    },
  },
  {
    path: ROUTER.PROMOTION,
    element: PromotionScreen,
  },
  {
    path: ROUTER.STORE_OMI_PHARMA,
    element: ListStoreScreen,
  },
  {
    path: ROUTER.DELIVERY_POLICY_SCREEN,
    element: DeliveryPolicyScreen,
  },
  {
    path: ROUTER.OMI_POINT_SCREEN,
    element: OmiPointScreen,
  },
  // {
  //   path: ROUTER.ORDERING_GUIDE_SCREEN,
  //   element: OrderingGuideScreen,
  // },
  {
    path: ROUTER.PRIVACY_POLICY_SCREEN,
    element: PrivacyPolicyScreen,
  },
  {
    path: ROUTER.RETURN_POLICY_SCREEN,
    element: ReturnPolicyScreen,
  },
  {
    path: ROUTER.TERMS_OF_SERVICE_SCREEN,
    element: TermsOfServiceScreen,
  },
  {
    path: ROUTER.ABOUT_OMI,
    element: IntroOmiPharmaScreen,
  },
  {
    path: ROUTER.REGULAR_CONFIRM_SCREEN,
    element: RegularBuyConfirmScreen,
  },
]

export const PRIVATE_ROUTE: RouterProps[] = [
  {
    path: ROUTER.ACCOUNT_SCREEN,
    element: UserAccountScreen,
  },
  {
    path: ROUTER.USER_REVIEW_SCREEN,
    element: UserReviewScreen,
  },
  {
    path: ROUTER.USER_NOTIFICATION_SCREEN,
    element: UserNotificationScreen,
  },
  {
    path: ROUTER.USER_REGULAR_SCREEN,
    element: UserRegularBuyScreen,
  },
  {
    path: ROUTER.PAYMENT_METHOD_SCREEN,
    element: UserPaymentMethodScreen,
  },
  {
    path: ROUTER.ORDER_SCREEN,
    element: UserOrderScreen,
  },
  {
    path: ROUTER.ORDER_DETAIL_SCREEN,
    element: UseOrderDetailScreen,
  },
  {
    path: ROUTER.USER_PRODUCT_SCREEN,
    element: UserProductScreen,
  },
  {
    path: ROUTER.USER_VOUCHER_SCREEN,
    element: UserVoucherScreen,
  },
  {
    path: ROUTER.USER_POINT_SCREEN,
    element: PersonalPointScreen,
  },
  {
    path: ROUTER.PREVIEW_PRODUCT,
    element: PreviewProduct,
  },
  {
    path: ROUTER.PREVIEW_NEWS,
    element: PreviewNews,
  },
  {
    path: ROUTER.PREVIEW_NEWS_REVIEW,
    element: PreviewNews,
  },
  {
    path: ROUTER.PREVIEW_HEALTH,
    element: PreviewHealth,
  },
]
