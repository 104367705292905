import { brandType } from '../../models/brand'
import { Responses } from '../../models/types'
import Network from '../Network'
import axiosClientAdmin from '../request'

const brandAPI = {
  getList(): Promise<Responses<brandType>> {
    return axiosClientAdmin.get(Network.brand.getList)
  },
  getDetail(param: { slug: string }): Promise<Responses<brandType>> {
    return axiosClientAdmin.get(Network.brand.getDetail + param.slug)
  },
}
export default brandAPI
