import { useTranslation } from 'react-i18next'
import { FaCaretRight } from 'react-icons/fa'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectFrequency } from '../store/slice'
import { useState } from 'react'
import { getCartDetail, updateCartItem } from '../../../app/features/cart/cart-api'
interface props {
  allow_frequency: boolean
  cart_item_id: number
  value: string
}
function RenderChangeBuyType(props: props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { allow_frequency, value, cart_item_id } = props
  const frequencyOption = useAppSelector(selectFrequency)
  const option = frequencyOption?.frequency_option || []
  const frequencyValue = option.find((i) => i.name === value)
  const [selectedFrequency, setSelectedFrequency] = useState(frequencyValue?.id || 1)

  const handleChange = (e: any) => {
    setSelectedFrequency(Number(e.target.value))
  }

  const handleAllowFrequency = (a: boolean) => {
    dispatch(
      updateCartItem({
        cart_item_id: cart_item_id,
        is_frequency: a ? 1 : 0,
        order_frequency_option_id: Number(selectedFrequency),
      }),
    ).then((res: any) => {
      if (res?.payload?.code === 200) {
        dispatch(getCartDetail())
      }
    })
  }

  return (
    <>
      <div className='item-content-box-right__buy-type__title'>
        {allow_frequency ? <p>{t('fastCart.switchToRetail')}</p> : <p>{t('fastCart.discountRecurringPurchases')}</p>}
        <FaCaretRight color='#008080' />
      </div>
      <div className='item-content-box-right__buy-type__dropdown'>
        {allow_frequency ? (
          <ul>
            <li>{t('fastCart.changeBuyContent1')}</li>
            <li>{t('fastCart.changeBuyContent2')}</li>
          </ul>
        ) : (
          <ul>
            <li>{t('fastCart.changeBuyContent5')}</li>
          </ul>
        )}

        <div className='item-content-box-right__buy-type__dropdown__confirm-box'>
          <span>{allow_frequency ? t('fastCart.stopMakingRegularPurchases') : t('fastCart.deliveryCycle')}</span>
          {!allow_frequency ? (
            <select value={selectedFrequency} onChange={handleChange}>
              {option.map((opt, index) => {
                return (
                  <option key={index} value={opt.id}>
                    {opt.name}
                  </option>
                )
              })}
            </select>
          ) : null}
        </div>
        <div className='item-content-box-right__buy-type__dropdown__btn-wrap'>
          <button className='item-content-box-right__buy-type__dropdown__btn-wrap__btn-cancel'>
            {t('fastCart.cancel')}
          </button>
          {allow_frequency ? (
            <button
              onClick={() => handleAllowFrequency(false)}
              className='item-content-box-right__buy-type__dropdown__btn-wrap__btn-confirm'
            >
              {t('fastCart.buyRetail')}
            </button>
          ) : (
            <button
              onClick={() => handleAllowFrequency(true)}
              className='item-content-box-right__buy-type__dropdown__btn-wrap__btn-confirm'
            >
              {t('fastCart.orderPeriodically')}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default RenderChangeBuyType
