import { UpDateAddItemCartDtoType, ResponseCartType, SelectedAllDtoType, SelectedAllType } from '../../models/cart'
import { AddWishListItemToCartDtoType } from '../../models/cart'
import { Responses } from '../../models/types'
import Network from '../Network'
import axiosClientAdmin from '../request'

const cartAPI = {
  getCart(): Promise<Responses<ResponseCartType>> {
    return axiosClientAdmin.get(Network.cart.getCart)
  },
  updateItem(params: UpDateAddItemCartDtoType): Promise<Responses<boolean>> {
    return axiosClientAdmin.post(Network.cart.updateItem, params)
  },
  addItem(params: UpDateAddItemCartDtoType): Promise<Responses<boolean>> {
    return axiosClientAdmin.post(Network.cart.addItem, params)
  },
  deleteItem(id: string): Promise<Responses<boolean>> {
    return axiosClientAdmin.post(Network.cart.deleteItem, {
      cart_item_id: id,
    })
  },
  selectAllItem(params: SelectedAllDtoType): Promise<Responses<SelectedAllType>> {
    return axiosClientAdmin.post(Network.cart.selectedAll, params)
  },
  addWishListItemToCart(params: AddWishListItemToCartDtoType): Promise<Responses<[]>> {
    return axiosClientAdmin.post(Network.cart.addWishListItemToCart, params)
  },
}
export default cartAPI
