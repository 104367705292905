import { RootState } from '../store'

//drugKnowledge
export const drugKnowledgeStore = (state: RootState) => state.drugKnowledge

//auth
export const authStore = (state: RootState) => state.auth

//brand
export const brandStore = (state: RootState) => state.brand
