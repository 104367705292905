export const formatNumberWithZero = (value: number | undefined) => {
  if (value === undefined) return
  if (value >= 10 || value <= -10) return `${value}`
  if (value >= 0) return `0${value}`
  if (value < 0) return `-0${Math.abs(value)}`
}

export const formatDiscount = (value: number | undefined, isAddSubtract = true) => {
  if (value === undefined) return
  if (isAddSubtract) return `-${value}%`
  return `${value}%`
}

export const formatStarRating = (value: number | undefined, totalStar = 5) => {
  if (!value) return `0/${totalStar}`
  return `${value}/${totalStar}`
}
