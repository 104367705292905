// export const validateLength = (value: string, length: number) => {
//   const va = `^\.{0,${length}}$`
//   const regex = new RegExp(va)
//   return regex.test(value)
// }
export class RequiredVerify {
  value: string
  public message?: string | undefined
  public key: string
  public length?: number | undefined

  constructor(value: string, message: string, key: string, length?: number) {
    this.value = value
    this.message = message || undefined
    this.key = key
    this.length = length || undefined
  }

  validate() {
    return this.value !== null && this.value !== undefined && this.value !== ''
  }
}

// export class RequireVerifyLength extends RequiredVerify {
//   constructor(value: string, message: string, key: string, length?: number) {
//     super(value, message, key, length)
//   }

//   validate(): boolean {
//     let result = super.validate()
//     if (result) {
//       result = validateLength(this.value, this.length ?? 0)
//     }
//     return result
//   }
// }

export class yearVerify extends RequiredVerify {
  constructor(value: string, message: string, value2?: any) {
    super(value, message, value2)
  }

  validate(): boolean {
    let result = super.validate()
    if (result) {
      result = validateYear(this.value)
    }
    return result
  }
}

export class EmailVerify extends RequiredVerify {
  constructor(value: string, message: string, value2?: any) {
    super(value, message, value2)
  }

  validate(): boolean {
    let result = super.validate()
    if (result) {
      result = validateEmail(this.value)
    }
    return result
  }
}

export class fieldVerify extends RequiredVerify {
  constructor(value: string, message: string, value2?: any) {
    super(value, message, value2)
  }

  validate(): boolean {
    let result = super.validate()
    if (result) {
      result = validateString_Number(this.value)
    }
    return result
  }
}

export class fileExcelVerify extends RequiredVerify {
  constructor(value: string, message: string, value2?: any) {
    super(value, message, value2)
  }

  validate(): boolean {
    let result = super.validate()
    if (result) {
      result = fileExcel(this.value)
    }
    return result
  }
}

export const fileExcel = (val: string) => {
  const re = /.*\.(xlsx|xls)/g
  // const regex = new RegExp(re)
  return re.test(val)
}

export const validateString_Number = (val: string) => {
  const re = '[a-z0-9_]'
  const regex = new RegExp(re)
  return regex.test(val)
}

export const validateYear = (year: string) => {
  const va = /^\d{4}$/
  return va.test(year)
}

export const validateEmail = (email: string) => {
  // const va = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]$/
  const va = /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/
  return va.test(email)
}
