import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { deleteItem, getCartDetail, updateCartItem } from '../../app/features/cart/cart-api'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import ic_delete from '../../assets/icons/DetailProductScreen/ic_delete_outline.svg'
import ic_regular from '../../assets/icons/DetailProductScreen/ic_regular.svg'
import ic_dec from '../../assets/icons/ic_dec.svg'
import ic_inc from '../../assets/icons/ic_inc.svg'
import { formatPriceVND } from '../../helpers/formatPrice'
import { selectIsLoading } from '../fastCart/store/slice'
import { ROUTER } from '../../router/config'

type Props = {
  showFull: boolean
  item: any
}

const FastCartItem: React.FC<Props> = ({ showFull, item }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [activeChange, setActiveChange] = useState(false)
  const [quantity, setQuantity] = useState(item.quantity)
  const isLoading = useAppSelector(selectIsLoading)

  const itemPrice = item.price ?? 0

  useEffect(() => {
    if (item) {
      setQuantity(item.quantity)
    }
  }, [item])

  const classActive = activeChange
    ? 'fast-cart-item__image-box__quantity-wrap active'
    : 'fast-cart-item__image-box__quantity-wrap'

  const onPressChange = () => {
    if (!showFull) return
    setActiveChange(!activeChange)
  }

  const handleChangeNumber = (value: number) => {
    const count = quantity + value
    if (count > 99) {
      setQuantity(99)
    } else if (count < 1) {
      setQuantity(1)
    } else {
      setQuantity(count)
    }
  }

  const handleDeleteItem = () => {
    dispatch(deleteItem(item.cart_item_id)).then((res: any) => {
      if (res?.payload?.code === 200) {
        dispatch(getCartDetail())
      }
    })
  }

  useEffect(() => {
    if (item.quantity !== quantity && !isLoading) {
      dispatch(
        updateCartItem({
          cart_item_id: item.cart_item_id,
          quantity: quantity,
        }),
      ).then((res: any) => {
        if (res?.payload?.code === 200) {
          dispatch(getCartDetail())
        }
      })
    }
  }, [isLoading, quantity])

  return (
    <div className='fast-cart-item'>
      <div className='fast-cart-item__image-box'>
        <Link className='fast-cart-item__image-box__image' to={ROUTER.PRODUCT_DETAIL + item.slug}>
          <div className='fast-cart-item-img'>
            <img src={item?.images?.[0]} alt='thumb-product' />
          </div>
        </Link>
        {item?.allow_frequency === 1 && (
          <img src={ic_regular} className='fast-cart-item__image-box__regular-icon' alt='ic-regular' />
        )}
        <img
          onClick={handleDeleteItem}
          src={ic_delete}
          className='fast-cart-item__image-box__delete-btn'
          alt='ic-delete'
        />
        <div className={classActive}>
          <button onClick={() => handleChangeNumber(-1)}>
            <img src={ic_dec} alt={'dec-icon'} />
          </button>
          <input type={'number'} value={quantity} readOnly={true} onClick={onPressChange} />
          <button onClick={() => handleChangeNumber(1)}>
            <img src={ic_inc} alt={'inc-icon'} />
          </button>
        </div>
      </div>
      {showFull ? (
        <div className='fast-cart-item__content-box'>
          <a className='fast-cart-item__content-box__name' href='# '>
            {item.name}
          </a>
          <p className='fast-cart-item__content-box__price'>{formatPriceVND(itemPrice)}</p>
          <button className='fast-cart-item__content-box__button'>{t('fastAccessCart.buyAfter')}</button>
        </div>
      ) : null}
    </div>
  )
}

export default FastCartItem
