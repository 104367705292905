import { Navigate, Outlet } from 'react-router-dom'
import { KEY_AUTH } from '../constant'
import { getToken } from '../helpers/cookieHelper'
import { toastSuccessErr } from '../helpers/toast'
import { ROUTER } from './config'
import { useTranslation } from 'react-i18next'

const ProctectedRoute: React.FC = () => {
  const { t } = useTranslation()
  const accessToken = getToken(KEY_AUTH)

  if (!accessToken) {
    const openAuthModalBtn = document.getElementById('openAuthModalBtn')
    openAuthModalBtn && openAuthModalBtn.click()
    toastSuccessErr({ type: 'error', message: t('needLogin') })
    return <Navigate to={ROUTER.HOME_PAGE} replace />
  }
  return <Outlet />
}

export default ProctectedRoute
