// LoadingOverlay.js
import '../assets/scss/LoadingOverlay.scss'
interface props {
  isLoading: boolean
}
const LoadingOverlay = (props: props) => {
  const { isLoading } = props
  return (
    isLoading && (
      <div className='loading-overlay'>
        <i className='fas fa-spinner fa-spin' />
      </div>
    )
  )
}

export default LoadingOverlay
