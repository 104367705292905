import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { Post } from '../../../models/newsDetailType'

export const postApi = createApi({
  reducerPath: 'postAPi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getPostList: build.mutation<ResponseData<Post[]> | undefined, unknown>({
      query: (data) => ({
        url: Network.news.getList,
        method: 'post',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<Post[]>) => {
        return rawResult
      },
    }),
    getPostListQr: build.query<ResponseData<Post[]>, unknown>({
      query: (data) => ({
        url: Network.news.getList,
        method: 'post',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<Post[]>) => {
        return rawResult
      },
    }),
    getPostDetail: build.query<Post | undefined, string>({
      query: (slug: string | undefined) => ({
        url: Network.news.newsDetailItem + slug,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<Post>) => {
        const resData = rawResult.data
        return resData
      },
    }),
    getPostPreview: build.query<Post | undefined, unknown>({
      query: (params: any) => ({
        url: Network.news.newsDetailItem + params.slug,
        method: 'get',
        params: params,
      }),
      transformResponse: (rawResult: ResponseData<Post>) => {
        const resData = rawResult.data
        return resData
      },
    }),
    getProductSuggest: build.mutation<any | undefined, unknown>({
      query: (params: any) => ({
        url: Network.news.getProductSuggest,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<any>) => {
        return rawResult.data
      },
    }),
  }),
})

export const {
  useGetPostListMutation,
  useGetPostListQrQuery,
  useGetPostDetailQuery,
  useGetPostPreviewQuery,
  useGetProductSuggestMutation,
} = postApi

export default postApi
