import { Link } from 'react-router-dom'

type Props = {
  to: string
  title?: string
  children?: React.ReactNode | string
}

export const LinkText: React.FC<Props> = (props) => {
  return (
    <Link to={props.to || '#'} title={props?.title || ''}>
      {props.children}
    </Link>
  )
}
