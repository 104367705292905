import { createApi } from '@reduxjs/toolkit/query/react'
import Network, { API_ROOT } from '../../../../apis/Network'
import { ResponseData } from '../../../../models/types'
import { FilterType, GetUserProductDto, UserProductItem } from '../../../../models/userProduct'
import { axiosBaseQuery } from '../../../hooks'

const userSeenListApi = createApi({
  reducerPath: 'userSeenListApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getSeenList: build.mutation<ResponseData<UserProductItem[]> | undefined, unknown>({
      query: (params: GetUserProductDto) => ({
        url: Network.userProductSeenList.getList,
        method: 'post',
        data: params,
      }),
    }),
    getFilter: build.mutation<FilterType | undefined, unknown>({
      query: () => ({
        url: Network.userProductSeenList.getFilters,
        method: 'post',
        data: { type: 'viewed' },
      }),
      transformResponse: (rawResponse: ResponseData<FilterType>) => {
        return rawResponse.data
      },
    }),
  }),
})

export const { useGetFilterMutation, useGetSeenListMutation } = userSeenListApi

export default userSeenListApi
