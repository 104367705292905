import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { languageJA, languageVI } from '../constant'

export const slice = createSlice({
  name: 'store',
  initialState: {
    isLogin: false,
    language: 'vi',
  },
  reducers: {
    changeIsLogin: (state, action) => {
      state.isLogin = action.payload
    },
    changeLanguage: (state, action) => {
      state.language = action.payload
      localStorage.setItem('languageOmiPharma', action.payload)
      document.documentElement.lang = action.payload === languageJA ? 'ja' : languageVI
    },
  },
})

export const selectIslogin = (state: RootState) => state[slice.name].isLogin
export const selectLanguage = (state: RootState) => state[slice.name].language

export const { changeIsLogin, changeLanguage } = slice.actions

export const StoreReducer = slice.reducer
