import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const slice = createSlice({
  name: 'userPaymentMethodPage',
  initialState: {
    cardList: [] as any[],
    historyPaymentList: [] as any[],
    startDateFilter: '',
    endDateFilter: '',
  },
  reducers: {
    changeCardList: (state, action) => {
      state.cardList = action.payload
    },
    changeStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload
    },
    changeEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload
    },
    changeHistoryPaymentList: (state, action) => {
      state.historyPaymentList = action.payload
    },
  },
})

export const selectCardList = (state: RootState) => state[slice.name].cardList
export const selectStartDateFilter = (state: RootState) => state[slice.name].startDateFilter
export const selectEndDateFilter = (state: RootState) => state[slice.name].endDateFilter
export const selectHistoryPaymentList = (state: RootState) => state[slice.name].historyPaymentList

export const { changeCardList, changeStartDateFilter, changeEndDateFilter, changeHistoryPaymentList } = slice.actions

export const UserPaymentMethodReducer = slice.reducer
