import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_BANK } from '../../../apis/Network'
import { BankType, ResponseData } from '../../../models/types'

export const bankApi = createApi({
  reducerPath: 'bankApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_BANK }),
  endpoints: (build) => ({
    getBankList: build.query<BankType[] | undefined, unknown>({
      query: () => ({
        url: Network.bank.getList,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<BankType[]>) => {
        const data = rawResult.data
        return data
      },
    }),
  }),
})

export const { useGetBankListQuery } = bankApi

export default bankApi
