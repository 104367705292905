import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProductDetailType } from '../../../models/types'

export const slice = createSlice({
  name: 'userReviewPage',
  initialState: {
    productReviewList: [] as any[],
    pharmacistReviewList: [] as any[],
    shopReviewList: [] as any[],
    serviceReviewList: [] as any[],
    suggestedList: [] as ProductDetailType[],
  },
  reducers: {
    changeProductReviewList: (state, action) => {
      state.productReviewList = action.payload
    },
    changePharmacistReviewList: (state, action) => {
      state.pharmacistReviewList = action.payload
    },
    changeShopReviewList: (state, action) => {
      state.shopReviewList = action.payload
    },
    changeServiceReviewList: (state, action) => {
      state.serviceReviewList = action.payload
    },
    changeSuggestedList: (state, action) => {
      state.suggestedList = action.payload
    },
  },
})

export const selectProductReviewList = (state: RootState) => state[slice.name].productReviewList
export const selectPharmacistReviewList = (state: RootState) => state[slice.name].pharmacistReviewList
export const selectShopReviewList = (state: RootState) => state[slice.name].shopReviewList
export const selectServiceReviewList = (state: RootState) => state[slice.name].serviceReviewList
export const selectSuggestedList = (state: RootState) => state[slice.name].suggestedList

export const {
  changeProductReviewList,
  changePharmacistReviewList,
  changeShopReviewList,
  changeServiceReviewList,
  changeSuggestedList,
} = slice.actions

export const UserReviewReducer = slice.reducer
