import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { BaseReview, ProductReview } from '../../../models/order'

export const slice = createSlice({
  name: 'userOrderDetailPage',
  initialState: {
    productReviewList: [] as ProductReview[],
    shippingReview: [] as BaseReview[],
    consultReview: [] as BaseReview[],
  },
  reducers: {
    changeProductReview: (state, action) => {
      state.productReviewList = action.payload
    },
    changeShippingReview: (state, action) => {
      state.shippingReview = action.payload
    },
    changeConsultReview: (state, action) => {
      state.consultReview = action.payload
    },
  },
})

export const orderDetailStore = (state: RootState) => state[slice.name]

export const { changeProductReview, changeShippingReview, changeConsultReview } = slice.actions

export const UserOrderDetailReducer = slice.reducer
