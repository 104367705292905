export const KEYS = {
  TEM_TOKEN: 'tem_token',
}

export const setTemToken = (token: string | undefined) => {
  if (token) {
    localStorage.setItem(KEYS.TEM_TOKEN, token)
  }
}

export const getTemToken = () => {
  return localStorage.getItem(KEYS.TEM_TOKEN)
}
