import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { categoryType } from '../../../models/typeCategory'

const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getListCategory: build.query<categoryType[] | undefined, unknown>({
      query: () => ({
        url: Network.category.getList,
        method: 'GET',
      }),
      transformResponse: (rawData: ResponseData<categoryType[]>) => {
        return rawData.data
      },
    }),
    getCategoryBySlug: build.query<categoryType | undefined, unknown>({
      query: (params: { slug: string; type?: number }) => ({
        url: params.slug ? Network.category.getList + '/' + params.slug : Network.category.getList,
        method: 'GET',
        params: {
          type: params.type,
        },
      }),
      transformResponse: (rawData: ResponseData<categoryType>) => {
        return rawData.data
      },
    }),
    getCategoryBySlugMTT: build.mutation<categoryType | undefined, unknown>({
      query: (params: {
        slug: string
        data?: {
          type?: number
          keyword?: string
        }
      }) => ({
        url: params.slug ? Network.category.getList + '/' + params.slug : Network.category.getList,
        method: 'GET',
        params: params.data,
      }),
      transformResponse: (rawData: ResponseData<categoryType>) => {
        return rawData.data
      },
    }),
    searchCategory: build.query<categoryType[] | undefined, unknown>({
      query: (param) => ({
        url: Network.category.searchCategory,
        method: 'POST',
        data: param,
      }),
      transformResponse: (rawData: ResponseData<categoryType[]>) => {
        return rawData.data
      },
    }),
  }),
})

export const {
  useGetListCategoryQuery,
  useGetCategoryBySlugQuery,
  useSearchCategoryQuery,
  useGetCategoryBySlugMTTMutation,
} = categoryApi

export default categoryApi
