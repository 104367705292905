import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import { getDetailBrand, getListBrand } from './brand-api'
import { brandType, initialBrand } from '../../../models/brand'
import { toastSuccessErr } from '../../../helpers/toast'
import { t } from 'i18next'
const initialStateBrand: initialBrand = {
  data: [],
  detail: {} as brandType,
  loading: false,
  error: false,
}

const BrandSlice = createSlice({
  name: 'Brand',
  initialState: initialStateBrand,
  reducers: {
    // action brand
    clearStateBrand(state) {
      state.data = []
      state.detail = {} as brandType
      state.loading = false
      state.error = false
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<initialBrand>) => {
    //getListBrand
    builder
      .addCase(getListBrand.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getListBrand.fulfilled, (state, action) => {
        const { data, code } = action.payload as any
        if (code === 200) {
          state.data = data
          state.error = false
        }
        if (code != 200) {
          state.error = true
        }
        state.loading = false
      })
      .addCase(getListBrand.rejected, (state) => {
        state.loading = false
      })
    //getDetailBrand
    builder
      .addCase(getDetailBrand.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getDetailBrand.fulfilled, (state, action) => {
        const { data, code } = action.payload as any
        if (code === 200) {
          state.detail = data
          state.error = false
        }
        if (code != 200) {
          state.error = true
          toastSuccessErr({ type: 'error', message: t('brandNotExist') })
        }
        state.loading = false
      })
      .addCase(getDetailBrand.rejected, (state) => {
        state.loading = false
      })
  },
})
export const { clearStateBrand } = BrandSlice.actions
const { reducer } = BrandSlice
export default reducer
