import {
  CreateOrderResponse,
  OrderDetailType,
  CreateOrderDto,
  ConfirmOrderDto,
  UpdateOrderDto,
  GetListOrderDto,
  OrderType,
} from '../../models/order'
import { ResponseData } from '../../models/types'
import Network from '../Network'
import axiosClientAdmin from '../request'

const orderAPI = {
  getOrderList(params: GetListOrderDto): Promise<ResponseData<OrderType[]>> {
    return axiosClientAdmin.post(Network.order.orderList, params)
  },
  getOrderDetail(): Promise<ResponseData<OrderDetailType>> {
    return axiosClientAdmin.get(Network.order.orderDetail)
  },
  createOrder(params: CreateOrderDto): Promise<ResponseData<CreateOrderResponse>> {
    return axiosClientAdmin.post(Network.order.createOrder, params)
  },
  confirmOrder(params: ConfirmOrderDto): Promise<ResponseData<boolean>> {
    return axiosClientAdmin.post(Network.order.confirmOrder, params)
  },
  buyNow(params: CreateOrderDto): Promise<ResponseData<boolean>> {
    return axiosClientAdmin.post(Network.order.buyNow, params)
  },
  updateOrder(params: UpdateOrderDto): Promise<ResponseData<boolean>> {
    return axiosClientAdmin.put(Network.order.updateOrder, params)
  },
}

export default orderAPI
