import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialAuth, userType } from '../../../models/auth'
import {
  fetchChangePassword,
  fetchForgotPassUser,
  fetchLogin,
  fetchLoginSocial,
  fetchRegister,
  fetchUpdateUser,
  fetchVerifyCheck,
  getInfoUser,
  getTemTokenAxios,
  verifyMail,
} from './auth-api'
import { toastSuccessErr } from '../../../helpers/toast'
import { setTemToken } from '../../../helpers/storage'
const initialStateAuth: initialAuth = {
  login: {},
  user: {} as userType,
  logout: {},
  changepass: {},
  forgotpass: {},
  updateUser: {},
  verify: {},
  verifycheck: {},
  register: {},
  loading: false,
  profilename: '',
  registerStep: {
    step: 1,
    email: '',
    verify_type: 'register',
  },
}

const authSlice = createSlice({
  name: 'Auth',
  initialState: initialStateAuth,
  reducers: {
    usersLoading(state) {
      // Use a "state machine" approach for loading state instead of booleans
      if (state.loading === false) {
        state.loading = true
      }
    },
    usersReceived(state, action: any) {
      if (state.loading === true) {
        state.loading = false
        state.user = action.payload
      }
    },
    changeUser(state, action: any) {
      state.user = action.payload as userType
      state.login = {}
    },
    clearUserState(state) {
      state.user = {} as userType
    },
    clearLoginState(state) {
      state.login = {}
      state.user = {} as userType
    },
    clearLogoutState(state) {
      state.logout = {}
    },
    clearRegisterState(state) {
      state.register = {}
    },
    clearVerifyState(state) {
      state.verify = {}
    },
    clearVerifyCheckState(state) {
      state.verifycheck = {}
    },
    clearChangePassState(state) {
      state.changepass = {}
    },
    clearForgotPassState(state) {
      state.forgotpass = {}
    },
    setProfileName(state, action) {
      state.profilename = action.payload
    },
    clearUpdateUsertState(state) {
      state.updateUser = {}
    },
    //registerStep
    next: (state, action: PayloadAction<number>) => {
      state.registerStep.step = action.payload
    },
    reset: (state) => {
      state.registerStep.step = 1
      state.registerStep.email = ''
      state.registerStep.verify_type = 'register'
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.registerStep.email = action.payload
    },
    setVerifyType: (state, action: PayloadAction<string>) => {
      state.registerStep.verify_type = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<initialAuth>) => {
    //fetchVerifyCheck
    builder
      .addCase(fetchVerifyCheck.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchVerifyCheck.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.verifycheck = action.payload
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(fetchVerifyCheck.rejected, (state) => {
        state.loading = false
      })
    //verify
    builder
      .addCase(verifyMail.pending, (state) => {
        state.loading = true
      })
      .addCase(verifyMail.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          toastSuccessErr({ type: 'success', message: message })
          state.verify = action.payload
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(verifyMail.rejected, (state) => {
        state.loading = false
      })
    //fetchRegister
    builder
      .addCase(fetchRegister.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRegister.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          toastSuccessErr({ type: 'success', message: message })
          state.register = action.payload
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(fetchRegister.rejected, (state) => {
        state.loading = false
      })
    // login;
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        state.login = action.payload
        // const value = action.payload
        // if (value.code === 200) {
        //   state.user = value.data?.user as any
        // }
        state.loading = false
        // state.error = false;
      })
      .addCase(fetchLogin.rejected, (state) => {
        state.loading = false
      })
    // login;
    builder
      .addCase(fetchLoginSocial.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchLoginSocial.fulfilled, (state, action) => {
        state.login = action.payload
        state.loading = false
        // state.error = false;
      })
      .addCase(fetchLoginSocial.rejected, (state) => {
        state.loading = false
      })
    // getInfoUser;
    builder
      .addCase(getInfoUser.pending, (state) => {
        state.loading = true
      })
      .addCase(getInfoUser.fulfilled, (state, action) => {
        const { data, code } = action.payload as any
        if (code === 200) {
          state.user = data
        }
        state.loading = false
      })
      .addCase(getInfoUser.rejected, (state) => {
        state.loading = false
      })
    //fetchForgotPassUser
    builder
      .addCase(fetchForgotPassUser.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchForgotPassUser.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          toastSuccessErr({ type: 'success', message: message })
          state.forgotpass = action.payload
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(fetchForgotPassUser.rejected, (state) => {
        state.loading = false
      })
    //fetchChangePassword
    builder
      .addCase(fetchChangePassword.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchChangePassword.fulfilled, (state, action) => {
        // const { code, message } = action.payload as any
        state.changepass = action.payload
        state.loading = false
      })
      .addCase(fetchChangePassword.rejected, (state) => {
        state.loading = false
      })
    //fetchUpdateUser
    builder
      .addCase(fetchUpdateUser.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchUpdateUser.fulfilled, (state, action) => {
        // const { code, message } = action.payload as any
        state.updateUser = action.payload
        state.loading = false
      })
      .addCase(fetchUpdateUser.rejected, (state) => {
        state.loading = false
      })
    // get Temprorary Token
    builder
      .addCase(getTemTokenAxios.pending, (state) => {
        state.loading = true
      })
      .addCase(getTemTokenAxios.fulfilled, (state, action) => {
        // const { code, message } = action.payload as any
        const token = action.payload?.data?._token
        if (token) {
          setTemToken(token)
        }
        state.loading = false
      })
      .addCase(getTemTokenAxios.rejected, (state) => {
        state.loading = false
      })
  },
})
export const {
  usersReceived,
  clearUserState,
  clearLoginState,
  clearLogoutState,
  clearRegisterState,
  clearVerifyState,
  clearVerifyCheckState,
  clearChangePassState,
  clearForgotPassState,
  setProfileName,
  setUserEmail,
  next,
  reset,
  setVerifyType,
  changeUser,
  clearUpdateUsertState,
} = authSlice.actions
const { reducer } = authSlice
export default reducer
