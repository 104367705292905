import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { clearVerifyCheckState, fetchVerifyCheck, next, verifyMail } from '../../../app/features/auth'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { authStore } from '../../../app/use-selector'
import { toastSuccessErr } from '../../../helpers/toast'
import { LinkText } from '../../common/LinkText'
import { ROUTER } from '../../../router/config'
import { regex } from '../../../helpers/ultis'

function OtpVery() {
  const dispatch = useAppDispatch()
  const auth = useAppSelector(authStore)
  const { t } = useTranslation()
  const [currentCount, setCount] = useState(120)
  const [code, setCode] = useState({
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    six: '',
  })
  const changeCode = (key: string, value: string) => {
    setCode((pre) => ({ ...pre, [key]: value }))
  }
  const clickEvent = (event: any, last: any) => {
    if (event.target.value.length) {
      if (window.document.getElementById(last)) {
        window.document.getElementById(last)?.focus()
      }
    }
  }

  const checkLeng = (value: string) => {
    if (value?.length < 1) {
      return false
    }
    return true
  }

  const checkVeryCode = (event: any) => {
    event.preventDefault()
    const { one, two, three, four, five, six } = code
    if (checkLeng(one) && checkLeng(two) && checkLeng(three) && checkLeng(four) && checkLeng(five) && checkLeng(six)) {
      const param = {
        identity: auth.registerStep.email,
        verify_code: one + two + three + four + five + six,
      }
      dispatch(fetchVerifyCheck(param))
      return
    }
    toastSuccessErr({ type: 'warning', message: t('plsEnterCode') })
  }

  const resend = () => {
    if (currentCount > 0) {
      return toastSuccessErr({ type: 'warning', message: t('resendCode', { number: currentCount }) })
    }
    const param = {
      type: auth.registerStep.email.trim().match(regex.phoneNumberRegExp) ? 'phone' : 'email',
      identity: auth.registerStep.email,
      verify_type: auth.registerStep.verify_type,
    }
    setCount(120)
    dispatch(verifyMail(param))
  }

  const timer = () => setCount(currentCount - 1)

  useEffect(() => {
    if (currentCount <= 0) {
      return
    }
    const id = setInterval(timer, 1000)
    return () => clearInterval(id)
  }, [currentCount])

  useEffect(() => {
    if (auth.verifycheck?.error) {
      dispatch(clearVerifyCheckState())
    } else if (auth.verifycheck?.code === 200) {
      dispatch(next(3))
      dispatch(clearVerifyCheckState())
    }
  }, [auth])

  const renderTime = (value: number) => {
    return value >= 10 ? value : '0' + value
  }

  function secondsToTime(secs: number) {
    // eslint-disable-next-line
    let hours = Math.floor(secs / (60 * 60))
    // eslint-disable-next-line
    let divisor_for_minutes = secs % (60 * 60)
    // eslint-disable-next-line
    let minutes = Math.floor(divisor_for_minutes / 60)
    // eslint-disable-next-line
    let divisor_for_seconds = divisor_for_minutes % 60
    // eslint-disable-next-line
    let seconds = Math.ceil(divisor_for_seconds)
    // eslint-disable-next-line
    let obj = {
      h: renderTime(hours),
      m: renderTime(minutes),
      s: renderTime(seconds),
    }
    return obj
  }

  return (
    <React.Fragment>
      <h2>{t('register.verifyAccount')}</h2>
      <div className='auth-box-name'>
        <p>{t('register.verifyAccountText')}</p>
        <div />
      </div>
      <form className='auth-box-form'>
        <div className='auth-box-form__input-otp'>
          <input
            type='text'
            pattern='\d*'
            id='one'
            value={code.one}
            maxLength={1}
            onKeyUp={(e) => clickEvent(e, 'two')}
            onChange={(e) => changeCode('one', e.target.value)}
            autoComplete='off'
          />
          <input
            type='text'
            pattern='\d*'
            id='two'
            value={code.two}
            maxLength={1}
            onKeyUp={(e) => clickEvent(e, 'three')}
            onChange={(e) => changeCode('two', e.target.value)}
            autoComplete='off'
          />
          <input
            type='text'
            pattern='\d*'
            id='three'
            value={code.three}
            maxLength={1}
            onKeyUp={(e) => clickEvent(e, 'four')}
            onChange={(e) => changeCode('three', e.target.value)}
            autoComplete='off'
          />
          <input
            type='text'
            pattern='\d*'
            id='four'
            value={code.four}
            maxLength={1}
            onKeyUp={(e) => clickEvent(e, 'five')}
            onChange={(e) => changeCode('four', e.target.value)}
            autoComplete='off'
          />
          <input
            type='text'
            pattern='\d*'
            id='five'
            value={code.five}
            maxLength={1}
            onKeyUp={(e) => clickEvent(e, 'six')}
            onChange={(e) => changeCode('five', e.target.value)}
            autoComplete='off'
          />
          <input
            type='text'
            pattern='\d*'
            id='six'
            value={code.six}
            maxLength={1}
            onChange={(e) => changeCode('six', e.target.value)}
            autoComplete='off'
          />
        </div>
        <p className='auth-box-text-time'>
          {secondsToTime(currentCount).m}:{secondsToTime(currentCount).s}
        </p>
        <p className='auth-box-text-confirm' onClick={resend}>
          {t('register.verifyAccountText')} <a>{t('register.resend')}</a>
        </p>
        <div className='auth-box-form__btn-submit'>
          <button onClick={checkVeryCode}>{t('register.verifyAccount')}</button>
        </div>
      </form>
      <p className='auth-box-text-confirm'>
        <Trans
          i18nKey='register.agreePolicy'
          components={{
            termLink: <LinkText to={ROUTER.TERMS_OF_SERVICE_SCREEN} title='Term Link' />,
            policyLink: <LinkText to={ROUTER.PRIVACY_POLICY_SCREEN} title='Policy Link' />,
          }}
        />
      </p>
    </React.Fragment>
  )
}

export default OtpVery
