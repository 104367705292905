import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronUp } from '../../assets/icons/fontAwaresome/chevron_up.svg'
import FastCartCheckoutItem from './fastCartProduct/fastCartCheckoutItem'
import { useAppSelector } from '../../app/hooks'
import { selectCartData } from './store/slice'
interface props {
  setShowFullProduct: (v: boolean) => void
}
function RenderListProductFull(props: props) {
  const { t } = useTranslation()
  const cartData = useAppSelector(selectCartData)

  const cartItem = cartData?.items || []

  return (
    <div className='modal-cart-content__info__product'>
      <div className='modal-cart-content__info__product__list-full'>
        {cartItem.map((item, index) => {
          return <FastCartCheckoutItem allow_frequency={!!item?.allow_frequency} key={index} item={item} />
        })}
      </div>
      <div className='modal-cart-content__info__product__btn-more'>
        <button onClick={() => props.setShowFullProduct(false)}>
          <p>{t('fastCart.collapse')}</p>
          <ChevronUp />
        </button>
      </div>
    </div>
  )
}

export default RenderListProductFull
