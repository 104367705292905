import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { cardType } from '../../../models/card'

export const settingApi = createApi({
  reducerPath: 'settingApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getListCard: build.query<cardType[] | undefined, unknown>({
      query: () => ({
        url: Network.card.getList,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<cardType[]>) => {
        return rawResult.data
      },
    }),
  }),
})

export const { useGetListCardQuery } = settingApi

export default settingApi
