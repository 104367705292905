import '../../assets/scss/support.scss'
import '../../assets/scss/orderingGuide.scss'
import { Trans, useTranslation } from 'react-i18next'
import NavOrderingGuide from './NavOrderingGuide'
import { LinkText } from '../../components/common/LinkText'
import { ROUTER } from '../../router/config'
function OrderingGuideScreen() {
  const { t } = useTranslation()

  return (
    // <div className='support-screen'>
    //   <div className='support-screen__main'>
    //     <div className='support-screen__main__content'>
    <div className='first-content'>
      <div className='ordering-guide-screen'>
        <NavOrderingGuide />
      </div>
    </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default OrderingGuideScreen
