import {
  LoginData,
  UserData,
  VerifyCheckRequest,
  VerifyRequest,
  LoginRequest,
  LoginSocialType,
  forgotType,
  changePassType,
  TemTokenData,
} from '../../models/auth'
import { Responses } from '../../models/types'
import Network from '../Network'
import axiosClientAdmin from '../request'

const authAPI = {
  verify(param: VerifyRequest): Promise<Responses<UserData>> {
    return axiosClientAdmin.post(Network.auth.verify, param)
  },
  verifyCheck(param: VerifyCheckRequest): Promise<Responses<UserData>> {
    return axiosClientAdmin.post(Network.auth.verifyCheck, param)
  },
  register(param: any): Promise<Responses<UserData>> {
    return axiosClientAdmin.post(Network.auth.register, param)
  },
  fetchLogin(param: LoginRequest): Promise<Responses<LoginData>> {
    return axiosClientAdmin.post(Network.auth.login, param)
  },
  getInfoUser(): Promise<Responses<any>> {
    return axiosClientAdmin.get(Network.users.getOne)
  },
  fetchLoginSocial(param: LoginSocialType): Promise<Responses<LoginData>> {
    return axiosClientAdmin.post(Network.auth.loginSocial, param)
  },
  forgot(param: forgotType): Promise<Responses<UserData>> {
    return axiosClientAdmin.post(Network.auth.forgotPass, param)
  },
  changePassword(param: changePassType): Promise<Responses<UserData>> {
    return axiosClientAdmin.post(Network.auth.changePass, param)
  },
  updateUser(param: any): Promise<Responses<UserData>> {
    return axiosClientAdmin.post(Network.auth.updateUser, param)
  },
  getTemToken(): Promise<Responses<TemTokenData>> {
    return axiosClientAdmin.get(Network.auth.getTemToken)
  },
}
export default authAPI
