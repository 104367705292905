import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import NavLogin from './NavLogin'
import NavInstructOrder from './NavInstructOrder'
import NavInvoice from './NavInvoice'

const NavOrderingGuide: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className='prescription-screen__nav'>
      <ul className='nav nav-tabs' id='prescription-screen__nav-tabs' role='tablist'>
        <li className='nav-item'>
          <a
            className='nav-link active'
            id='got-prescription-tab'
            data-bs-toggle='tab'
            data-bs-target='#got-prescription'
            role='tab'
            aria-controls='got-prescription'
            aria-selected='true'
          >
            <div className='icon nav-link__icon-got-prescription'></div>
            <p>{t('orderingInstructions.navLogin')}</p>
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link'
            id='no-prescription-tab'
            data-bs-toggle='tab'
            data-bs-target='#no-prescription'
            role='tab'
            aria-controls='no-prescription'
            aria-selected='false'
          >
            <div className='icon nav-link__icon-no-prescription'></div>
            <p>{t('orderingInstructions.navInstructOrder')}</p>
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link'
            id='no-prescription-tab1'
            data-bs-toggle='tab'
            data-bs-target='#no-prescription1'
            role='tab'
            aria-controls='no-prescription1'
            aria-selected='false'
          >
            <div className='icon nav-link__icon-no-invoice'></div>
            <p>{t('orderingInstructions.navVAT')}</p>
          </a>
        </li>
      </ul>
      <div className='tab-content ordering-guide-screen__content' id='prescription-screen__nav-tab-content'>
        <div
          className='tab-pane fade show active'
          id='got-prescription'
          role='tabpanel'
          aria-labelledby='got-prescription-tab'
        >
          <NavLogin />
        </div>
        <div className='tab-pane fade' id='no-prescription' role='tabpanel' aria-labelledby='no-prescription-tab'>
          <NavInstructOrder />
        </div>

        <div className='tab-pane fade' id='no-prescription1' role='tabpanel' aria-labelledby='no-prescription-tab1'>
          <NavInvoice />
        </div>
      </div>
    </div>
  )
}
export default NavOrderingGuide
