import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProductDetailType } from '../../../models/types'
import { toastSuccessErr } from '../../../helpers/toast'
import { getOrderList } from '../../../app/features/order/order-api'
import { OrderType } from '../../../models/order'

export const slice = createSlice({
  name: 'userOrderPage',
  initialState: {
    loading: false,
    orderData: [] as OrderType[],
    relatedProductList: [] as ProductDetailType[],
    startDateFilter: '',
    endDateFilter: '',
    searchCode: '',
    totalOrder: 0,
    selectedOrder: undefined as OrderType | undefined,
  },
  reducers: {
    changeRelatedProductList: (state, action) => {
      state.relatedProductList = action.payload
    },
    changeSearchCode: (state, action) => {
      state.searchCode = action.payload
    },
    changeStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload
    },
    changeEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload
    },
    changeOrderData: (state, action) => {
      state.orderData = action.payload
    },
    changeSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOrderList.pending, (state) => {
        state.loading = true
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        const data = action.payload.data
        if (code === 200) {
          state.orderData = data
          state.totalOrder = typeof action.payload.total === 'number' ? action.payload.total : 0
          if (data.length > 0) {
            state.selectedOrder = data[0]
          }
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(getOrderList.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectRelatedProductList = (state: RootState) => state[slice.name].relatedProductList
export const selectStartDateFilter = (state: RootState) => state[slice.name].startDateFilter
export const selectEndDateFilter = (state: RootState) => state[slice.name].endDateFilter
export const selectOrderData = (state: RootState) => state[slice.name].orderData
export const selectSearchCode = (state: RootState) => state[slice.name].searchCode
export const selectTotalOrder = (state: RootState) => state[slice.name].totalOrder
export const selectLoading = (state: RootState) => state[slice.name].loading
export const selectSelectedOrder = (state: RootState) => state[slice.name].selectedOrder

export const {
  changeRelatedProductList,
  changeStartDateFilter,
  changeEndDateFilter,
  changeOrderData,
  changeSearchCode,
  changeSelectedOrder,
} = slice.actions

export const UserOrderReducer = slice.reducer
