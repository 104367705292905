import { CityType, DistrictType, ResponseData, WardType } from '../../models/types'
import Network from '../Network'
import axiosClientAdmin from '../request'

const commonAPI = {
  getCityList(): Promise<ResponseData<CityType[]>> {
    return axiosClientAdmin.get(Network.common.city.getList)
  },
  getCity(param: number | string): Promise<ResponseData<CityType>> {
    return axiosClientAdmin.get(Network.common.city.getItem(param))
  },
  getDistrictList(id: number | string): Promise<ResponseData<DistrictType[]>> {
    return axiosClientAdmin.post(Network.common.district.getList, {
      filter: {
        province_id: { equal: id },
      },
      pageSize: 999,
    })
  },
  getWardList(id: number | string): Promise<ResponseData<WardType[]>> {
    return axiosClientAdmin.post(Network.common.ward.getList, {
      filter: {
        district_id: { equal: id },
      },
      pageSize: 999,
    })
  },
}

export default commonAPI
