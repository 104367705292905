import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
// import { toast } from 'react-toastify'
import { fetchDrugKnowledge, getDetailDrugKnowledge } from './categoryDrugKnowledge-api'
import { dataDetailDrugKnowledge, dataDrugKnowledge, initialCategoryDrugKnowledge } from '../../../models/typeCategory'
const initialStateCategory: initialCategoryDrugKnowledge = {
  data: {} as dataDrugKnowledge,
  detail: {} as dataDetailDrugKnowledge,
  loading: false,
  error: false,
}

const CategoryDrugKnowledgeSlice = createSlice({
  name: 'CategoryDrugKnowledge',
  initialState: initialStateCategory,
  reducers: {
    // action fetchDrugKnowledge
    clearStateCategoryDrugKnowledge(state) {
      state.data = {} as dataDrugKnowledge
      state.detail = {} as dataDetailDrugKnowledge
      state.loading = false
      state.error = false
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<initialCategoryDrugKnowledge>) => {
    //fetchDrugKnowledge
    builder
      .addCase(fetchDrugKnowledge.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(fetchDrugKnowledge.fulfilled, (state, action) => {
        const { data, code } = action.payload as any
        if (code === 200) {
          state.data = data
          state.error = false
        }
        if (code != 200) {
          state.error = true
        }
        state.loading = false
      })
      .addCase(fetchDrugKnowledge.rejected, (state) => {
        state.loading = false
      })
    //getDetailDrugKnowledge
    builder
      .addCase(getDetailDrugKnowledge.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getDetailDrugKnowledge.fulfilled, (state, action) => {
        const { data, code } = action.payload as any
        if (code === 200) {
          state.detail = data
          state.error = false
        }
        if (code != 200) {
          state.error = true
        }
        state.loading = false
      })
      .addCase(getDetailDrugKnowledge.rejected, (state) => {
        state.loading = false
      })
  },
})
export const { clearStateCategoryDrugKnowledge } = CategoryDrugKnowledgeSlice.actions
const { reducer } = CategoryDrugKnowledgeSlice
export default reducer
