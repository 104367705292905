import { createAsyncThunk } from '@reduxjs/toolkit'
import userWishListAPI from '../../../../apis/api/api-user-wish-list'
import {
  AddItemToListDtoType,
  DeleteProductFromListDtoType,
  WishListItemDtoType,
} from '../../../../models/userWishList'
import { GetRelatedProductDto } from '../../../../models/userProduct'

export const getListWishList = createAsyncThunk('/userProduct/getListWishList', async () => {
  return await userWishListAPI.getListWishList()
})

export const getWishListItem = createAsyncThunk('/userProduct/getWishListItem', async (id: string | number) => {
  return await userWishListAPI.getWishListItem(id)
})

export const addWishListItem = createAsyncThunk('/userProduct/addWishListItem', async (params: WishListItemDtoType) => {
  return await userWishListAPI.addWishListItem(params)
})

export const updateWishListItem = createAsyncThunk(
  '/userProduct/updateWishListItem',
  async (params: WishListItemDtoType) => {
    return await userWishListAPI.updateWishListItem(params)
  },
)

export const deleteWishList = createAsyncThunk('/userProduct/deleteWishList', async (id: string | number) => {
  return await userWishListAPI.deleteWishList(id)
})

export const addItemToWishList = createAsyncThunk(
  '/userProduct/addItemToWishList',
  async (params: AddItemToListDtoType) => {
    return await userWishListAPI.addItemToWishList(params)
  },
)

export const deleteItemFromWishList = createAsyncThunk(
  '/userProduct/deleteItemFromWishList',
  async (params: DeleteProductFromListDtoType) => {
    return await userWishListAPI.deleteItemFromWishList(params)
  },
)

export const getWishListRelatedProduct = createAsyncThunk(
  '/userProduct/getWishListRelatedProduct',
  async (params: GetRelatedProductDto) => {
    return await userWishListAPI.getWishListRelatedProduct(params)
  },
)
