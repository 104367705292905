import { createAsyncThunk } from '@reduxjs/toolkit'
import authAPI from '../../../apis/api/api-auth'
import {
  LoginRequest,
  LoginSocialType,
  VerifyCheckRequest,
  VerifyRequest,
  changePassType,
  forgotType,
} from '../../../models/auth'

export const fetchVerifyCheck = createAsyncThunk('/Auth/fetchVerifyCheck', async (param: VerifyCheckRequest) => {
  return await authAPI.verifyCheck(param)
})

export const verifyMail = createAsyncThunk('/Auth/verify', async (param: VerifyRequest) => {
  return await authAPI.verify(param)
})

export const fetchRegister = createAsyncThunk('/Auth/fetchRegister', async (param: any) => {
  return await authAPI.register(param)
})

export const fetchLogin = createAsyncThunk('/Auth/fetchLogin', async (param: LoginRequest) => {
  return await authAPI.fetchLogin(param)
})

export const getInfoUser = createAsyncThunk('/Auth/getInfoUser', async () => {
  return await authAPI.getInfoUser()
})

export const fetchLoginSocial = createAsyncThunk('/Auth/fetchLoginSocial', async (param: LoginSocialType) => {
  return await authAPI.fetchLoginSocial(param)
})

export const fetchForgotPassUser = createAsyncThunk('/Auth/fetchForgotPassUser', async (param: forgotType) => {
  return await authAPI.forgot(param)
})

export const fetchChangePassword = createAsyncThunk('/Auth/fetchChangePassword', async (param: changePassType) => {
  return await authAPI.changePassword(param)
})

export const fetchUpdateUser = createAsyncThunk('/Auth/fetchUpdateUser', async (param: any) => {
  return await authAPI.updateUser(param)
})

export const getTemTokenAxios = createAsyncThunk('/Auth/getTemToken', async () => {
  return await authAPI.getTemToken()
})
