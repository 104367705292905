import { Suspense } from 'react'
import '../assets/scss/category.scss'
import { Route, Routes } from 'react-router-dom'
import { PRIVATE_ROUTE, ROUTERS } from '.'
import ProctectedRoute from './ProtectedRoute'

const RenderRoute = () => {
  return (
    <Routes>
      {ROUTERS.map((route, ps) => (
        <Route
          path={route.path}
          element={
            <Suspense fallback={null}>
              <route.element props={route.props} />
            </Suspense>
          }
          key={ps}
        >
          {route?.children
            ? route.children.map((child) => (
                <Route
                  path={child.path}
                  element={
                    <Suspense fallback={null}>
                      <child.element props={child.props} />
                    </Suspense>
                  }
                  key={ps}
                />
              ))
            : null}
        </Route>
      ))}
      <Route element={<ProctectedRoute />}>
        {PRIVATE_ROUTE.map((route, ps) => (
          <Route
            path={route.path}
            element={
              <Suspense fallback={null}>
                <route.element />
              </Suspense>
            }
            key={ps}
          />
        ))}
      </Route>
    </Routes>
  )
}

export default RenderRoute
