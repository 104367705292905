import ic_campaign from '../../../assets/icons/ic_campaign.png'
import ic_campaign_gift from '../../../assets/icons/ic_campaign_gift.png'
import ic_campaign_jp from '../../../assets/icons/ic_campaign_jp.png'
import icon_gift_jp from '../../../assets/icons/ic_campaign_gift_jp.png'
import { formatPriceVND } from '../../../helpers/formatPrice'
import { ProductInItemType } from '../../../models/cart'
import { GREAT_DEAL_TYPE } from '../../../constant'
interface props {
  checked?: boolean
  // remove question icon when handle checkout
  item: ProductInItemType
  isGift?: boolean
  type?: number
}
const lang =
  localStorage.getItem('languageOmiPharma') || (navigator.languages ? navigator.languages[0] : navigator.language)

function FastCartGiftItem(props: props) {
  const { item, type } = props
  const { images, name, price, price_origin, discount_percent } = item
  const displayPrice = price
  const discountPercent = discount_percent ?? 0

  return (
    <div className='fast-cart-gift-item'>
      <div className='fast-cart-gift-item__image-box'>
        <img src={images[0]} alt='product-thumb' />
      </div>
      <div className='fast-cart-gift-item__content-box'>
        <div className='gift-item-content-box-left'>
          <div className='gift-item-content-box-left__campaign-tag'>
            <img
              src={
                type === GREAT_DEAL_TYPE
                  ? lang === 'jp'
                    ? ic_campaign_jp
                    : ic_campaign
                  : lang === 'jp'
                  ? icon_gift_jp
                  : ic_campaign_gift
              }
              alt='campaign-tag'
            />
          </div>
          <p className='gift-item-content-box-left__product-name'>{name}</p>
          <div className='gift-item-content-box-left__price-box'>
            <span className='gift-item-content-box-left__price-box__price-active'>{formatPriceVND(displayPrice)}</span>
            {discountPercent > 0 && (
              <span className='gift-item-content-box-left__price-box__discount'>{discount_percent}%</span>
            )}
            {price_origin > displayPrice && (
              <span className='gift-item-content-box-left__price-box__price-origin'>
                {formatPriceVND(price_origin)}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FastCartGiftItem
