interface props {
  onchange: (a: boolean) => void
  checked?: boolean
}

function SwitchBox(props: props) {
  const onChange = (bool: boolean) => {
    props.onchange(bool)
  }
  return (
    <label className='switch-box'>
      <input type='checkbox' checked={props?.checked} onChange={(e) => onChange(e.target.checked)} />
      <span className='slider round' />
    </label>
  )
}

export default SwitchBox
