import ic_dec from '../../assets/icons/ic_dec.svg'
import ic_inc from '../../assets/icons/ic_inc.svg'
import '../../assets/scss/detailProduct.scss'

type Props = {
  quantity: number
  changeQuantity: (a: number) => void
  disable?: boolean
}

const QuantitySelect: React.FC<Props> = ({ quantity, changeQuantity, disable }) => {
  const handleChangeQuantity = (value: number) => {
    if (disable) return
    const count = quantity + value
    if (count > 99) {
      changeQuantity(99)
    } else if (count < 1) {
      changeQuantity(1)
    } else {
      changeQuantity(count)
    }
  }

  return (
    <div className='quantity-wrap'>
      <button
        className={disable ? 'disable' : ''}
        disabled={disable}
        onClick={() => handleChangeQuantity(-1)}
        style={{
          pointerEvents: disable ? 'none' : 'auto',
          opacity: disable ? 0.5 : 1,
        }}
      >
        <img src={ic_dec} alt={'dec-icon'} />
      </button>
      <input type={'number'} value={quantity} readOnly={true} />
      <button
        className={disable ? 'disable' : ''}
        disabled={disable}
        onClick={() => handleChangeQuantity(1)}
        style={{
          pointerEvents: disable ? 'none' : 'auto',
          opacity: disable ? 0.5 : 1,
        }}
      >
        <img src={ic_inc} alt={'inc-icon'} />
      </button>
    </div>
  )
}

export default QuantitySelect
