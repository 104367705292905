import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { getProductDetail, getProductReview } from '../../../app/features/productDetail/productDetail-api'
import {
  CampaignType,
  FrequencyOptionType,
  ProductComboType,
  ProductDocType,
  ProductReviewScoreType,
  PromotionType,
  ReviewDataType,
  SimilarProductType,
  VariantType,
  product,
} from '../../../models/product'
import { selectAllItem } from '../../../app/features/cart/cart-api'
import { getRecommendList, getSimilarList } from '../../../app/features/productList/product-list-api'

export const slice = createSlice({
  name: 'productDetail',
  initialState: {
    isSelectPackage: false,
    selectedPackagePrice: 0,
    activeVoucher: 0,
    buyMethod: 0,
    quantity: 1,
    periodOption: undefined as FrequencyOptionType[] | undefined,
    selectedPeriod: undefined as FrequencyOptionType | undefined,
    loading: false,
    selectedType: 0,

    data: {} as product,
    reviewList: [] as ReviewDataType[],
    comboList: [] as ProductComboType[],
    similarProductList: [] as SimilarProductType[],
    recommendProductList: [] as SimilarProductType[],
    productMeta: {} as ProductDocType,
    rateDetail: {} as ProductReviewScoreType,
    voucherList: [] as PromotionType[],
    typeList: [] as VariantType[],
    currReviewPage: 1,
    reviewPageSize: 10,
    totalReview: 0,
    wishListIncludeProduct: [] as number[],

    selectedCampaign: undefined as CampaignType | undefined,
    productPrice: 0,
    discountPercent: 0,
    discountPrice: 0,
    productCode: '',
    originalPrice: 0,
  },
  reducers: {
    changeIsSelectPackage: (state, action) => {
      state.isSelectPackage = action.payload
    },
    changeProductCode: (state, action) => {
      state.productCode = action.payload
    },
    changeSelectedPackagePrice: (state, action) => {
      state.selectedPackagePrice = action.payload
    },
    changeActiveVoucher: (state, action) => {
      state.activeVoucher = action.payload
    },
    changeBuyMethod: (state, action) => {
      state.buyMethod = action.payload
    },
    changePeriodOption: (state, action) => {
      state.periodOption = action.payload
    },
    changeSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload
    },
    changeQuantity: (state, action) => {
      state.quantity = action.payload
    },
    changeCurrReviewPage: (state, action) => {
      state.currReviewPage = action.payload
    },
    changeWishListIncludeProduct: (state, action) => {
      state.wishListIncludeProduct = action.payload
    },
    changeSelectedCampaign: (state, action) => {
      state.selectedCampaign = action.payload
    },
    changeProductPrice: (state, action) => {
      state.productPrice = action.payload
    },
    changeDiscountPercent: (state, action) => {
      state.discountPercent = action.payload
    },
    changeDiscountPrice: (state, action) => {
      state.discountPrice = action.payload
    },
    changeSelectedType: (state, action) => {
      state.selectedType = action.payload
    },
    changeOriginalPrice: (state, action) => {
      state.originalPrice = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(getProductDetail.fulfilled, (state, action) => {
        const { data, code } = action.payload
        if (code === 200 && data) {
          const mainItem = data.variants?.reduce((min, product) => (product.price < min.price ? product : min))
          state.data = data
          state.typeList = data.variants
          state.voucherList = data.promotions
          state.comboList = data.combos.filter((i) => [2, 3].includes(i.type))
          state.productMeta = data.detail
          state.wishListIncludeProduct = data.wish_lists
          state.periodOption = data?.frequency?.frequency_option || []
          state.selectedPeriod = data?.frequency?.frequency_option?.[0] || undefined
          state.productPrice = mainItem?.price
          state.discountPercent = mainItem?.discount_percent
          state.originalPrice = mainItem?.price_origin
          state.productCode = mainItem?.code
          state.selectedType = mainItem?.id
          state.isSelectPackage = false
          state.selectedPackagePrice = 0
          const flashSale = mainItem?.campaigns.find((c) => c.type === 2)
          state.selectedCampaign = flashSale
          state.discountPrice = mainItem?.discount_price
          state.originalPrice = mainItem?.price_origin ?? mainItem?.price
        }
        state.loading = false
      })
      .addCase(getProductDetail.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getSimilarList.pending, () => {
        // state.loading = true
      })
      .addCase(getSimilarList.fulfilled, (state, action) => {
        const { data, code } = action.payload
        if (code === 200) {
          state.similarProductList = data
        }
        // state.loading = false
      })
      .addCase(getSimilarList.rejected, (state) => {
        state.loading = false
      })
    builder
      .addCase(getProductReview.pending, () => {
        // state.loading = true
      })
      .addCase(getProductReview.fulfilled, (state, action) => {
        const { data, code } = action.payload
        if (code === 200 && data?.review_list) {
          state.rateDetail = data.review_score
          state.reviewList = data.review_list.data
          state.totalReview = data.review_list.total
        }
        // state.loading = false
      })
      .addCase(getProductReview.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getRecommendList.pending, () => {
        // state.loading = true
      })
      .addCase(getRecommendList.fulfilled, (state, action) => {
        const { data, code } = action.payload
        if (code === 200) {
          state.recommendProductList = data
        }
        // state.loading = false
      })
      .addCase(getRecommendList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(selectAllItem.pending, () => {
        // state.loading = true
      })
      .addCase(selectAllItem.fulfilled, () => {
        // state.loading = false
      })
      .addCase(selectAllItem.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectData = (state: RootState) => state[slice.name].data

export const selectIsSelectPackage = (state: RootState) => state[slice.name].isSelectPackage

export const selectActiveVoucher = (state: RootState) => state[slice.name].activeVoucher

export const selectBuyMethod = (state: RootState) => state[slice.name].buyMethod
export const selectQuantity = (state: RootState) => state[slice.name].quantity
export const selectPeriodOption = (state: RootState) => state[slice.name].periodOption
export const selectSelectedPeriod = (state: RootState) => state[slice.name].selectedPeriod
export const selectSelectedType = (state: RootState) => state[slice.name].selectedType
export const selectTypeList = (state: RootState) => state[slice.name].typeList
export const selectVoucherList = (state: RootState) => state[slice.name].voucherList
export const selectComboList = (state: RootState) => state[slice.name].comboList
export const selectSimilarProductList = (state: RootState) => state[slice.name].similarProductList
export const selectRecommendProductList = (state: RootState) => state[slice.name].recommendProductList
export const selectProductMeta = (state: RootState) => state[slice.name].productMeta

export const selectReviewPageSize = (state: RootState) => state[slice.name].reviewPageSize
export const selectCurrReviewPage = (state: RootState) => state[slice.name].currReviewPage
export const selecttotalReview = (state: RootState) => state[slice.name].totalReview
export const selectReviewList = (state: RootState) => state[slice.name].reviewList
export const selectRateDetail = (state: RootState) => state[slice.name].rateDetail
export const selectWishListIncludeProduct = (state: RootState) => state[slice.name].wishListIncludeProduct
export const selectSelectedCampaign = (state: RootState) => state[slice.name].selectedCampaign
export const selectProductPrice = (state: RootState) => state[slice.name].productPrice
export const selectDiscountPercent = (state: RootState) => state[slice.name].discountPercent
export const selectDiscountPrice = (state: RootState) => state[slice.name].discountPrice
export const selectLoading = (state: RootState) => state[slice.name].loading
export const selectSelectedPackagePrice = (state: RootState) => state[slice.name].selectedPackagePrice
export const selectProductCode = (state: RootState) => state[slice.name].productCode
export const selectOriginalPrice = (state: RootState) => state[slice.name].originalPrice

export const {
  changeIsSelectPackage,
  changeProductCode,
  changeSelectedPackagePrice,
  changeActiveVoucher,
  changeBuyMethod,
  changeQuantity,
  changeSelectedPeriod,
  changePeriodOption,
  changeCurrReviewPage,
  changeWishListIncludeProduct,
  changeSelectedCampaign,
  changeProductPrice,
  changeDiscountPercent,
  changeSelectedType,
  changeDiscountPrice,
  changeOriginalPrice,
} = slice.actions

export const ProductDetailReducer = slice.reducer
