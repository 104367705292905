import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProductDetailType } from '../../../models/types'

export const slice = createSlice({
  name: 'userRegularBuyPage',
  initialState: {
    data: [] as any[],
    suggestedList: [] as ProductDetailType[],
  },
  reducers: {
    changeSuggestedList: (state, action) => {
      state.suggestedList = action.payload
    },
    changeData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const selectSuggestedList = (state: RootState) => state[slice.name].suggestedList
export const selectData = (state: RootState) => state[slice.name].data

export const { changeSuggestedList, changeData } = slice.actions

export const UserRegularBuyReducer = slice.reducer
