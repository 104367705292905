import Network from '../Network'
import axiosClientAdmin from '../request'
import { Responses } from '../../models/types'
import { HomeSettingType } from '../../models/home'

const homeAPI = {
  getHomeSetting(): Promise<Responses<HomeSettingType>> {
    return axiosClientAdmin.get(Network.home.setting)
  },
}
export default homeAPI
