import { createAsyncThunk } from '@reduxjs/toolkit'
import addressAPI from '../../../apis/api/api-address'
import { AddressItemDtoType } from '../../../models/address'

export const getAddressList = createAsyncThunk('/userAccount/getAddressList', async () => {
  return await addressAPI.getListAddress()
})

export const putAddressItem = createAsyncThunk('/userAccount/putAddressItem', async (params: AddressItemDtoType) => {
  return await addressAPI.updateAddressItem(params)
})

export const addAddressItem = createAsyncThunk('/userAccount/addAddressItem', async (params: AddressItemDtoType) => {
  return await addressAPI.addAddressItem(params)
})

export const deleteAddressItem = createAsyncThunk('/userAccount/deleteAddressItem', async (id: string | number) => {
  return await addressAPI.deleteAddressItem(id)
})
