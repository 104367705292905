import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { createApi } from '@reduxjs/toolkit/query/react'

export const pointApi = createApi({
  reducerPath: 'pointApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getAccumulationHistoryList: build.mutation<any | undefined, unknown>({
      query: (params) => ({
        url: Network.accumulationHistory.getList,
        method: 'get',
        params,
      }),
      transformResponse: (rawResult: any) => {
        return rawResult.data
      },
    }),
  }),
})

export const { useGetAccumulationHistoryListMutation } = pointApi

export default pointApi
