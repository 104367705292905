import { useTranslation, Trans } from 'react-i18next'
import arrow from '../../assets/images/OrderingGuide/arrow.png'
import step_invoice_1 from '../../assets/images/OrderingGuide/navInvoice/step-invoice-1.png'
import step_invoice_2 from '../../assets/images/OrderingGuide/navInvoice/step2.png'
import step_invoice_3 from '../../assets/images/OrderingGuide/navInvoice/step3.png'
function NavInvoice() {
  const { t } = useTranslation()
  return (
    <div className='nav-login'>
      <div className='row'>
        <div className='col-lg-6 col-sm-6'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>{t('navInvoice.content1')}</strong>
          </p>
        </div>
      </div>
      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>{t('navInvoice.stepOne')}:</h6>
              <p className='mb-15'>{t('navInvoice.content2')}</p>
              <p className='custom-color hightlight'>
                <Trans i18nKey={'navInvoice.content3'} components={{ br: <br /> }} />
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_invoice_1} alt='Omi Pharma website screenshot' />
        </div>
      </div>
      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>{t('navInvoice.stepTwo')}:</h6>
              <p className='mb-15'>{t('navInvoice.content4')}</p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_invoice_3} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-7 col-sm-7'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>{t('navInvoice.content5')}</strong>
          </p>
        </div>
      </div>
      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>{t('navInvoice.stepOne')}:</h6>
              <p className='mb-15'>{t('navInvoice.content6')}</p>
              <p>
                <Trans i18nKey={'navInvoice.content7'} components={{ span: <span /> }} />
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_invoice_2} alt='Omi Pharma website screenshot' />
        </div>
      </div>
      <div className='row mb-45'>
        <div className='col-lg-6 col-sm-6 d-flex flex-column justify-content-center'>
          <div className='step-content'>
            <div className='how-to-do mb-15'>
              <h6>{t('navInvoice.stepTwo')}:</h6>
              <p className=''>{t('navInvoice.content8')}</p>
              <p>{t('navInvoice.content9')}</p>
              <p>{t('navInvoice.content10')}</p>
              <p className='mb-30'>{t('navInvoice.content11')}</p>
              <p>{t('navInvoice.content12')}</p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_invoice_3} alt='Omi Pharma website screenshot' />
        </div>
      </div>
      <div className='row mb-45'>
        <div className='col-lg-11 col-sm-11'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>{t('navInvoice.note')}:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do'>
              <p>
                <Trans i18nKey={'navInvoice.content13'} components={{ br: <br /> }} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NavInvoice
