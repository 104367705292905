import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProductDetailType } from '../../../models/types'

export const slice = createSlice({
  name: 'userProductPage',
  initialState: {
    currentPromotionList: [] as { banner: string; id: number; name: string; productList: ProductDetailType[] }[],
    needToBuyData: [] as any,
    selectedNeedToBuyCategory: {} as any,
  },
  reducers: {
    changeCurrentPromotionList: (state, action) => {
      state.currentPromotionList = action.payload
    },
    changeNeedToBuyData: (state, action) => {
      state.needToBuyData = action.payload
    },
    changeSelectedNeedToBuyCategory: (state, action) => {
      state.selectedNeedToBuyCategory = action.payload
    },
  },
})

export const selectCurrentPromotionList = (state: RootState) => state[slice.name].currentPromotionList
export const selectNeedToBuyData = (state: RootState) => state[slice.name].needToBuyData
export const selectSelectedNeedToBuyCategory = (state: RootState) => state[slice.name].selectedNeedToBuyCategory

export const { changeCurrentPromotionList, changeNeedToBuyData, changeSelectedNeedToBuyCategory } = slice.actions

export const UserProductReducer = slice.reducer
