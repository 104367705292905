import { createAsyncThunk } from '@reduxjs/toolkit'
import orderAPI from '../../../apis/api/api-order'
import { ConfirmOrderDto, CreateOrderDto, GetListOrderDto, UpdateOrderDto } from '../../../models/order'

export const getOrderList = createAsyncThunk('/OrderDetail/getOrderList', async (params: GetListOrderDto) => {
  return await orderAPI.getOrderList(params)
})

export const getOrderDetail = createAsyncThunk('/OrderDetail/getOrderDetail', async () => {
  return await orderAPI.getOrderDetail()
})

export const createOrder = createAsyncThunk('/OrderDetail/createOrder', async (params: CreateOrderDto) => {
  return await orderAPI.createOrder(params)
})

export const confirmOrder = createAsyncThunk('/OrderDetail/confirmOrder', async (params: ConfirmOrderDto) => {
  return await orderAPI.confirmOrder(params)
})

export const buyNow = createAsyncThunk('/OrderDetail/buyNow', async (params: CreateOrderDto) => {
  return await orderAPI.buyNow(params)
})

export const updateOrder = createAsyncThunk('/OrderDetail/updateOrder', async (params: UpdateOrderDto) => {
  return await orderAPI.updateOrder(params)
})
