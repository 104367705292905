import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import {
  BriefProduct,
  GetCagetoryDto,
  GetCampaignDto,
  GetMostViewProductDto,
  GetSuggestByOrderDto,
  GetSuggestTodayDto,
  SimilarProductType,
} from '../../../models/product'
import { ResponseData } from '../../../models/types'

const productListApi = createApi({
  reducerPath: 'productListApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getSimilarList: build.query<SimilarProductType[] | undefined, unknown>({
      query: (slug: string) => ({
        url: Network.productDetail.getSimilarList + slug,
        method: 'GET',
      }),
      transformResponse: (rawResult: ResponseData<SimilarProductType[]>) => {
        return rawResult.data
      },
    }),
    getSeenList: build.query<SimilarProductType[] | undefined, unknown>({
      query: () => ({
        url: Network.productDetail.getSeenList,
        method: 'GET',
      }),
      transformResponse: (rawResult: ResponseData<SimilarProductType[]>) => {
        return rawResult.data
      },
    }),
    getCategoryList: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetCagetoryDto) => ({
        url: Network.productDetail.getCategoryList,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getCampaignList: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetCampaignDto) => ({
        url: Network.productDetail.getCampaignList,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getSuggestTodayList: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetSuggestTodayDto) => ({
        url: Network.productDetail.getSuggestTodayList,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getProductByCategoryList: build.mutation<ResponseData<BriefProduct[]> | undefined, unknown>({
      query: (params: GetCagetoryDto) => ({
        url: Network.productDetail.getCategoryList,
        method: 'POST',
        data: params,
      }),
    }),
    getSuggestProductByOrder: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetSuggestByOrderDto) => ({
        url: Network.productDetail.getSuggestByOrder + params.id,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getMostViewProduct: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetMostViewProductDto) => ({
        url: Network.productDetail.getMostViewProduct,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
  }),
})

export const {
  useGetSimilarListQuery,
  useLazyGetSeenListQuery,
  useGetCampaignListMutation,
  useGetCategoryListMutation,
  useGetSuggestTodayListMutation,
  useGetProductByCategoryListMutation,
  useGetSuggestProductByOrderMutation,
  useGetMostViewProductMutation,
} = productListApi

export default productListApi
