import { createSlice } from '@reduxjs/toolkit'
import { WishListItemDetailType, WishListItemType } from '../../../../models/userWishList'
import {
  addItemToWishList,
  addWishListItem,
  deleteItemFromWishList,
  deleteWishList,
  getListWishList,
  getWishListItem,
  getWishListRelatedProduct,
  updateWishListItem,
} from './thunk'
import { RootState } from '../../../../app/store'
import { BriefProduct } from '../../../../models/product'

export const slice = createSlice({
  name: 'userProductWishList',
  initialState: {
    needToBuyData: [] as WishListItemType[],
    loading: false,
    selectedId: undefined as string | number | undefined,
    itemData: {} as WishListItemDetailType,
    relatedList: [] as BriefProduct[],
  },
  reducers: {
    changeSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListWishList.pending, (state) => {
        state.loading = true
      })
      .addCase(getListWishList.fulfilled, (state, action) => {
        const { data, code } = action.payload
        if (code === 200) {
          state.needToBuyData = data
          if (!data.some((item) => item.id === state.selectedId)) {
            state.selectedId = data[0]?.id
          }
        }
        state.loading = false
      })
      .addCase(getListWishList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getWishListItem.pending, (state) => {
        state.loading = true
      })
      .addCase(getWishListItem.fulfilled, (state, action) => {
        const { data, code } = action.payload
        if (code === 200) {
          state.itemData = data
        }
        state.loading = false
      })
      .addCase(getWishListItem.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(addWishListItem.pending, (state) => {
        state.loading = true
      })
      .addCase(addWishListItem.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(addWishListItem.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(updateWishListItem.pending, (state) => {
        state.loading = true
      })
      .addCase(updateWishListItem.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateWishListItem.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(deleteWishList.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteWishList.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteWishList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(addItemToWishList.pending, (state) => {
        state.loading = true
      })
      .addCase(addItemToWishList.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(addItemToWishList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(deleteItemFromWishList.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteItemFromWishList.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteItemFromWishList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getWishListRelatedProduct.pending, (state) => {
        state.loading = true
      })
      .addCase(getWishListRelatedProduct.fulfilled, (state, action) => {
        state.loading = false
        state.relatedList = action.payload.data ?? []
      })
      .addCase(getWishListRelatedProduct.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectSelectedId = (state: RootState) => state[slice.name].selectedId
export const selectListData = (state: RootState) => state[slice.name].needToBuyData
export const selectItemData = (state: RootState) => state[slice.name].itemData
export const selectRelatedList = (state: RootState) => state[slice.name].relatedList

export const { changeSelectedId } = slice.actions

export const UserProductWishListReducer = slice.reducer
