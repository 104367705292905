import { createAsyncThunk } from '@reduxjs/toolkit'
import cartAPI from '../../../apis/api/api-cart'
import { AddWishListItemToCartDtoType, SelectedAllDtoType, UpDateAddItemCartDtoType } from '../../../models/cart'

export const getCartDetail = createAsyncThunk('Cart/getCartDetail', async () => {
  return await cartAPI.getCart()
})

export const updateCartItem = createAsyncThunk('Cart/updateCartItem', async (params: UpDateAddItemCartDtoType) => {
  return await cartAPI.updateItem(params)
})

export const addCartItem = createAsyncThunk('Cart/addCartItem', async (params: UpDateAddItemCartDtoType) => {
  return await cartAPI.addItem(params)
})

export const deleteItem = createAsyncThunk('Cart/deleteItem', async (id: string) => {
  return await cartAPI.deleteItem(id)
})

export const selectAllItem = createAsyncThunk('Cart/selectAllItem', async (params: SelectedAllDtoType) => {
  return await cartAPI.selectAllItem(params)
})

export const addWishListItemToCart = createAsyncThunk(
  'Cart/addWishListItemToCart',
  async (params: AddWishListItemToCartDtoType) => {
    return await cartAPI.addWishListItemToCart(params)
  },
)
