import { createApi } from '@reduxjs/toolkit/query/react'
import Network, { API_ROOT } from '../../../apis/Network'
import { axiosBaseQuery } from '../../hooks'
import { HomeSettingType } from '../../../models/home'
import { ResponseData } from '../../../models/types'

const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getHomeSetting: build.query<HomeSettingType | undefined, unknown>({
      query: () => ({
        url: Network.home.setting,
        method: 'GET',
      }),
      transformResponse: (rawData: ResponseData<HomeSettingType>) => {
        return rawData.data
      },
    }),
  }),
})

export const { useGetHomeSettingQuery } = homeApi

export default homeApi
