import { createAsyncThunk } from '@reduxjs/toolkit'
import brandAPI from '../../../apis/api/api-brand'

export const getListBrand = createAsyncThunk('/brand/getListBrand', async () => {
  return await brandAPI.getList()
})

export const getDetailBrand = createAsyncThunk('/brand/getDetailBrand', async (param: { slug: string }) => {
  return await brandAPI.getDetail(param)
})
