import React, { useEffect, useState } from 'react'
import '../../assets/scss/auth.scss'
import img_fail from '../../assets/images/Auth/img_fail.png'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { authStore } from '../../app/use-selector'
import img_success from '../../assets/images/Auth/img_success.png'
import FormRegister from './registerStep/formRegister'
import OtpVery from './registerStep/otpVery'
import SendMail from './registerStep/sendMail'
import { clearRegisterState, next, reset } from '../../app/features/auth'

interface Props {
  changeRender: (a: number) => void
}

const Register: React.FC<Props> = ({ changeRender }) => {
  const auth = useAppSelector(authStore)
  const { t } = useTranslation()
  const [step, setStep] = useState<number>(1)
  const dispatch = useAppDispatch()

  const login = (number?: number) => {
    dispatch(clearRegisterState())
    dispatch(reset())
    if (changeRender) {
      changeRender(number ?? 0)
    }
  }

  const renderStep1 = () => {
    return (
      <SendMail
        changeRender={() => {
          changeRender(0)
        }}
        verify_type='register'
      />
    )
  }

  const renderStep3 = () => {
    return (
      <FormRegister
        changeRender={() => {
          changeRender(0)
        }}
      />
    )
  }

  const renderStep2 = () => {
    return <OtpVery />
  }

  const renderCaseSuccess = () => {
    return (
      <>
        <div className='auth-box-result'>
          <img src={img_success} alt='success' />
          <h3>{t('register.registerSuccess')}</h3>
          <p>{t('register.registerSuccessText')}</p>
        </div>
        <div className='auth-box-form__btn-submit'>
          <button onClick={() => login()}>{t('register.login')}</button>
        </div>
      </>
    )
  }

  const renderCaseFail = () => {
    return (
      <>
        <div className='auth-box-result'>
          <img src={img_fail} alt='fail' />
          <h3>{t('register.sendFail')}</h3>
          <p>{t('register.sendFailText')}</p>
        </div>
        <div className='auth-box-form__btn-submit'>
          <button onClick={() => login(1)}>{t('register.reRegister')}</button>
        </div>
      </>
    )
  }

  const renderStep4 = () => {
    return <>{auth.register.code === 200 ? renderCaseSuccess() : renderCaseFail()}</>
  }

  useEffect(() => {
    if (auth.registerStep.step) {
      setStep(auth.registerStep.step)
    }
    if (auth.register?.error) {
      dispatch(clearRegisterState())
    } else if (auth.register.code === 200) {
      dispatch(next(4))
    }
  }, [auth])

  return (
    <div className='auth-box-wrap auth-forgot-pass-box'>
      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step === 3 && renderStep3()}
      {step === 4 && renderStep4()}
    </div>
  )
}

export default Register
