export * from './brand-api'
export * from './brand-slice'
import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { BrandDetailDto, BrandDetailType, BrandListDto, BrandType } from '../../../models/brand'

const brandApi = createApi({
  reducerPath: 'brandApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getBrandList: build.mutation<ResponseData<BrandType[]> | undefined, unknown>({
      query: (params: BrandListDto) => ({
        url: Network.brand.getList,
        method: 'post',
        data: params,
      }),
    }),
    getBrand: build.mutation<BrandDetailType | undefined, unknown>({
      query: (params: BrandDetailDto) => ({
        url: Network.brand.getDetail + params.brandName,
        method: 'post',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BrandDetailType>) => {
        return rawResult.data
      },
    }),
  }),
})

export const { useGetBrandListMutation, useGetBrandMutation } = brandApi

export default brandApi
