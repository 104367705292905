import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProductDetailType } from '../../../models/types'

export const slice = createSlice({
  name: 'userNotifyPage',
  initialState: {
    userNotifyData: [] as any[],
    relatedProductList: [] as ProductDetailType[],
    startDateFilter: '',
    endDateFilter: '',
    notRead: {
      total: 0,
      promotions: 0,
      order: 0,
      system: 0,
    },
    unreadTotal: 0,
  },
  reducers: {
    changeUserNotifyData: (state, action) => {
      state.userNotifyData = action.payload
    },
    changeRelatedProductList: (state, action) => {
      state.relatedProductList = action.payload
    },
    changeStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload
    },
    changeEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload
    },
    changeNotRead: (state, action) => {
      state.notRead = action.payload
    },
    changeUnreadTotal: (state, action) => {
      state.unreadTotal = action.payload
    },
  },
})

export const selectUserNotifyData = (state: RootState) => state[slice.name].userNotifyData
export const selectRelatedProductList = (state: RootState) => state[slice.name].relatedProductList
export const selectStartDateFilter = (state: RootState) => state[slice.name].startDateFilter
export const selectEndDateFilter = (state: RootState) => state[slice.name].endDateFilter
export const selectNotRead = (state: RootState) => state[slice.name].notRead
export const selectUnreadTotal = (state: RootState) => state[slice.name].unreadTotal

export const {
  changeUserNotifyData,
  changeRelatedProductList,
  changeStartDateFilter,
  changeEndDateFilter,
  changeNotRead,
  changeUnreadTotal,
} = slice.actions

export const UserNotifyReducer = slice.reducer
