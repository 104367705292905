import { createAsyncThunk } from '@reduxjs/toolkit'
import productDetailAPI from '../../../apis/api/api-product-detail'
import { ProductReviewDetailDtoType } from '../../../models/product'

export const getProductDetail = createAsyncThunk('/ProductDetail/getProductDetail', async (slug: string) => {
  return await productDetailAPI.getProductDetail(slug)
})

export const getProductReview = createAsyncThunk(
  '/ProductDetail/getProductReview',
  async (params: ProductReviewDetailDtoType) => {
    return await productDetailAPI.getProductReview(params)
  },
)

export const getProductPreview = createAsyncThunk('/ProductDetail/getProductDetail', async (params: any) => {
  return await productDetailAPI.getProductPreview(params)
})
