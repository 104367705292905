import axiosClientAdmin from '../request'
import { ResponseData } from '../../models/types'
import Network from '../Network'
import {
  WishListItemType,
  WishListItemDtoType,
  WishListItemDetailType,
  AddItemToListDtoType,
  DeleteProductFromListDtoType,
} from '../../models/userWishList'
import { GetRelatedProductDto } from '../../models/userProduct'
import { BriefProduct } from '../../models/product'

const userWishListAPI = {
  getListWishList(): Promise<ResponseData<WishListItemType[]>> {
    return axiosClientAdmin.get(Network.userProductWishList.getList)
  },
  getWishListItem(id: string | number): Promise<ResponseData<WishListItemDetailType>> {
    return axiosClientAdmin.get(Network.userProductWishList.getItem + id)
  },
  addWishListItem(params: WishListItemDtoType): Promise<ResponseData<WishListItemType>> {
    return axiosClientAdmin.post(Network.userProductWishList.addItem, params)
  },
  updateWishListItem(params: WishListItemDtoType): Promise<ResponseData<boolean>> {
    return axiosClientAdmin.put(Network.userProductWishList.updateItem + params?.id, params)
  },
  deleteWishList(id: string | number): Promise<ResponseData<boolean>> {
    return axiosClientAdmin.delete(Network.userProductWishList.deleteItem + id)
  },
  addItemToWishList(params: AddItemToListDtoType): Promise<ResponseData<[]>> {
    return axiosClientAdmin.post(Network.userProductWishList.addToItem, params)
  },
  deleteItemFromWishList(params: DeleteProductFromListDtoType): Promise<ResponseData<1>> {
    return axiosClientAdmin.post(Network.userProductWishList.deleteFromItem, params)
  },
  getWishListRelatedProduct(params: GetRelatedProductDto): Promise<ResponseData<BriefProduct[]>> {
    return axiosClientAdmin.post(Network.userProductWishList.getWishListRelatedProduct, params)
  },
}

export default userWishListAPI
