import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import '../../assets/scss/auth.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { changeUser, clearLoginState, fetchLogin, fetchLoginSocial, getInfoUser } from '../../app/features/auth'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { authStore } from '../../app/use-selector'
import ic_hide from '../../assets/icons/Auth/ic_hide.svg'
import ic_mail from '../../assets/icons/Auth/ic_mail.svg'
import ic_pass from '../../assets/icons/Auth/ic_pass.svg'
import ic_show from '../../assets/icons/Auth/ic_show.svg'
// import login_apple from '../../assets/images/Auth/login_apple.png'
import login_fb from '../../assets/images/Auth/login_fb.png'
import login_gg from '../../assets/images/Auth/login_gg.png'
// import login_fb_ja from '../../assets/images/Auth/login_fb_ja.png'
// import login_gg_ja from '../../assets/images/Auth/login_gg_ja.png'
import { KEY_AUTH, KEY_REMEMBER, KEY_USER } from '../../constant'
import { setToken } from '../../helpers/cookieHelper'
import { getProfile } from '../../helpers/googleHelper'
import { toastSuccessErr } from '../../helpers/toast'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { toast } from 'react-toastify'
import { ReactComponent as Check } from '../../assets/icons/fontAwaresome/check.svg'
// import { getLang } from '../../i18n'

interface Props {
  changeRender: (a: number) => void
}

const Login: React.FC<Props> = ({ changeRender }) => {
  const fb_app_id = process.env.REACT_APP_FACEBOOK_APP_ID ?? ''
  const auth = useAppSelector(authStore)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showPass, setShowPass] = useState<boolean>(false)
  const [rememberPassword, setRememberPassword] = useState<boolean>(false)

  const loginSchema = yup
    .object({
      email: yup.string().required(t('requiredEmailPhone')),
      password: yup.string().required(t('requirePass')),
    })
    .required()
  type FormData = yup.InferType<typeof loginSchema>
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(loginSchema),
  })

  const onSubmit = (data: FormData) => {
    const param = {
      email: data?.email?.trim(),
      password: data?.password?.trim(),
      remember_me: rememberPassword,
    }
    dispatch(fetchLogin(param))
  }

  const handleGoogleSuccess = async (data: TokenResponse) => {
    const profile = await getProfile(data.access_token)
    const param = {
      provider: 'google',
      remember_token: data.access_token,
      email: profile.email,
      name: profile.name,
      avatar: profile.picture,
    }
    dispatch(fetchLoginSocial(param))
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: (error: any) => toast.error(`${error}`),
  })

  const responseFacebook = (response: any) => {
    const param = {
      provider: 'facebook',
      remember_token: response?.accessToken,
      email: response?.email,
      name: response?.name,
      avatar: response?.picture?.data?.url,
    }
    dispatch(fetchLoginSocial(param))
  }

  useEffect(() => {
    const items = localStorage.getItem(KEY_USER) && JSON.parse(localStorage.getItem(KEY_USER) || '')
    const rememberLocal = localStorage.getItem(KEY_REMEMBER) && JSON.parse(localStorage.getItem(KEY_REMEMBER) || '')
    setRememberPassword(rememberLocal ? true : false)
    if (rememberLocal && items) {
      setValue('email', items?.email ?? '')
      setValue('password', items?.password ?? '')
    }
    if (!rememberLocal) {
      localStorage.removeItem(KEY_USER)
    }
  }, [])

  useEffect(() => {
    const { data, code } = auth.login
    if (code === 200 && data) {
      const user = data?.user as any
      location.reload()
      if (user) dispatch(changeUser(user))
      const values = getValues()
      if (rememberPassword) {
        localStorage.setItem(
          KEY_USER,
          JSON.stringify({
            email: values?.email?.trim(),
            password: values?.password?.trim(),
          }),
        )
        localStorage.setItem(KEY_REMEMBER, JSON.stringify(rememberPassword))
      }
      if (!rememberPassword) {
        localStorage.removeItem(KEY_REMEMBER)
      }
      setToken(KEY_AUTH, data?.access_token, Number(data?.expires_in ?? 0) * 60)
      if (data?.access_token) {
        dispatch(getInfoUser())
      }
      $('#modalAuthInput').hide()
      $('body').removeClass('modal-open').css({ overflow: 'auto', 'padding-right': 0 })
      $('.modal-backdrop').remove()
    }
    if (code && code != 200) {
      toastSuccessErr({ type: 'error', message: t('loginError') })
      dispatch(clearLoginState())
    }
  }, [auth])

  return (
    <div className='auth-box-wrap auth-login-box'>
      <h2>{t('login.title')}</h2>
      <p className='auth-sub-title'>
        {t('login.loginQuestion')} <a onClick={() => changeRender(1)}>{t('login.registerAccount')}</a>
      </p>
      <form className='auth-box-form' onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <label>{t('emailPhone')}</label>
        <div className='auth-box-form__item'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_mail} alt='icon' />
            </div>
            <input {...register('email')} type='text' placeholder={t('enterEmailPhone')} autoComplete='new-email' />
          </div>
          <p>{errors?.email?.message}</p>
        </div>
        <label>{t('login.password')}</label>
        <div className='auth-box-form__item'>
          <div className='auth-box-form__input'>
            <div className='auth-box-form__input__icon'>
              <img src={ic_pass} alt='icon' />
            </div>
            <input
              type={showPass ? 'text' : 'password'}
              {...register('password')}
              placeholder={t('login.passwordPlaceholder')}
              autoComplete='new-password'
            />
            <button type='button' onClick={() => setShowPass(!showPass)}>
              <img src={showPass ? ic_show : ic_hide} alt='icon' style={{ width: '20px' }} />
            </button>
          </div>
          <p>{errors?.password?.message}</p>
        </div>
        <div className='auth-box-form__option'>
          <div className='auth-box-form__option__check'>
            <div
              onClick={() => setRememberPassword(!rememberPassword)}
              className={`check-radio ${rememberPassword ? 'active' : ''}`}
            >
              <Check />
            </div>
            <p>{t('login.rememberPassword')}</p>
          </div>
          <span className='auth-box-form__option__reset' onClick={() => changeRender(2)}>
            {t('login.forgotPassword')}
          </span>
        </div>

        <div className='auth-box-form__btn-submit'>
          <button type='submit'>{t('login.title')}</button>
        </div>
      </form>
      <div className='auth-login-box__btn-login'>
        <button>
          <img
            // src={getLang() === languageJA ? login_gg_ja : login_gg}
            src={login_gg}
            alt='icon-method-login'
            onClick={() => loginGoogle()}
          />
        </button>
        <FacebookLogin
          appId={`${fb_app_id}`}
          // autoLoad
          fields='name,email,picture'
          callback={responseFacebook}
          render={(renderProps: any) => {
            return (
              <button onClick={renderProps.onClick}>
                <img
                  // src={getLang() === languageJA ? login_fb_ja : login_fb}
                  src={login_fb}
                  alt='icon-method-login'
                />
              </button>
            )
          }}
        />

        {/* <button>
          <img src={login_apple} alt='icon-method-login' />
        </button> */}
      </div>
    </div>
  )
}

export default Login
