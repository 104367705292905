import { useTranslation } from 'react-i18next'
import arrow from '../../assets/images/OrderingGuide/arrow.png'
import login1 from '../../assets/images/OrderingGuide/login-1.png'
import step_order_1 from '../../assets/images/OrderingGuide/navOrder/step-order-1.png'
import step_order_2 from '../../assets/images/OrderingGuide/navOrder/step-order-2.png'
import step_order_3 from '../../assets/images/OrderingGuide/navOrder/step-order-3.png'
import step_order_4 from '../../assets/images/OrderingGuide/navOrder/step-order-4.png'
import step_order_5 from '../../assets/images/OrderingGuide/navOrder/step-order-5.png'
import step_order_6 from '../../assets/images/OrderingGuide/navOrder/step-order-6.png'
import step_order_7 from '../../assets/images/OrderingGuide/navOrder/step-order-7.png'
import step_order_8 from '../../assets/images/OrderingGuide/navOrder/step-order-8.png'
import step_order_9 from '../../assets/images/OrderingGuide/navOrder/step-order-9.png'
import step_order_10 from '../../assets/images/OrderingGuide/navOrder/step-order-10.png'
import step_order_11 from '../../assets/images/OrderingGuide/navOrder/step-order-11.png'
import step_order_12 from '../../assets/images/OrderingGuide/navOrder/step-order-12.png'
import step_order_13 from '../../assets/images/OrderingGuide/navOrder/step-order-13.png'
import step_order_14 from '../../assets/images/OrderingGuide/navOrder/step-order-14.png'
function NavInstructOrder() {
  const { t } = useTranslation()
  return (
    <div className='nav-login'>
      <div className='row'>
        <div className='col-lg-6 col-sm-6'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 0: Có 2 cách để thực hiện bước 0</strong>
          </p>
        </div>
      </div>

      <div className='mb-45 d-flex ml-25'>
        <div className='m-2'>
          <img src={step_order_1} alt='' />
        </div>

        <div className='m-2'>
          <img src={step_order_2} alt='' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 1:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do'>
              <p>
                Vào giao diện mua sắm hàng hóa, tham khảo và lựa chọn sản phẩm được giới thiệu hoặc chương trình khuyến
                mãi phù hợp. Hoặc Nhập tên sản phẩm muốn tìm kiếm tại ô tìm kiếm hoặc tìm kiếm trực tiếp trong danh mục
                sản phẩm.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_order_3} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-30'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 2:</strong>
          </p>
          <div className='ml-15 step-content'>
            <div className='how-to-do'>
              <p className='mb-15'>
                Tham khảo và lựa chọn các sản phẩm được giới thiệu hoặc chương trình khuyến mại phù hợp với nhu cầu,
                mong muốn.
              </p>
              <p className='mb-15 hightlight'>
                Quý khách lưu ý về sản phẩm <span className='custom-color'>THUỐC và THUỐC KÊ ĐƠN</span> cần được tư vấn
                bởi Dược sĩ trước khi mua.
              </p>
              <p className=''>Tại giao diện sản phẩm tìm kiếm, Quý khách có thể tham khảo thông tin với 2 chế độ:</p>
              <p className='custom-color mb-15'>
                Chế độ thông tin ngắn <br />
                Chế độ thông tin chi tiết vui lòng bấm vào sản phẩm để tới trang chi tiết sản phẩm
              </p>
              <p>
                Một số thông tin cần biết khi lựa chọn sản phẩm: <br /> - Tên và hình ảnh sản phẩm <br /> - Số lượng sản
                phẩm đã bán và đánh giá sản phẩm <br />- Thông tin sản phẩm (Giá cả, quy cách, mô tả, công dụng...){' '}
                <br />- Loại sản phẩm, Gói bán (nếu có); <br />- Mã giảm giá từ Omi Pharma có thể áp dụng cho sản phẩm
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6 d-flex align-items-center'>
          <img src={step_order_4} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-4 col-sm-4'></div>
        <div className='col-lg-4 col-sm-4 d-flex'>
          <img src={step_order_5} alt='' />
          <img src={step_order_6} alt='' className='ml-30' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5 '>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 3:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do'>
              <p>
                Chọn Thêm vào giỏ hàng để sản phẩm vào giỏ hàng và tiếp tục mua sắm. Hoặc nhấp vào Mua ngay để tới trang
                Thanh toán.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_order_7} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 4:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do'>
              <p>
                Tại Mục Thông tin giỏ hàng bên phải màn hình, Quý khách có thể nhấp dấu "-" hoặc "+" để thêm/bớt số
                lượng sản phẩm hoặc xóa sản phẩm khỏi giỏ hàng. Sau đó nhấp Đặt hàng để đi tới Thanh toán.{' '}
              </p>
            </div>
          </div>
        </div>  
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_order_8} alt='Omi Pharma website screenshot' />
        </div>
      </div>
      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 5:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do'>
              <p className='mb-15'>
                Tại giao diện Trang thanh toán, vui lòng điền thông tin địa chỉ giao hàng gồm các thông tin:{' '}
              </p>
              <p>- Họ và tên</p>
              <p>- Số điện thoại liên hệ</p>
              <p>- Email</p>
              <p>- Chọn Thành phố, Quận/huyện, Phường/xã</p>
              <p>- Địa chỉ giao hàng chi tiết</p>
              <p>- Loại hình địa chỉ (nhà riêng hay công ty)</p>
              <p className='mb-15'>
                Sau đó, hệ thống Omi Pharma sẽ tính tiền cước vận chuyển dựa trên địa chỉ mà quý khách cung cấp
              </p>
              <p>
                Đối với khách hàng đã từng mua hàng nhưng chưa đăng nhập tài khoản trước đây thì thông tin về (họ tên,
                số điện thoại, địa chỉ giao hàng) đã được Omi Pharma lưu sẵn. Quý khách có thể chọn địa chỉ có sẵn đã sử
                dụng trước đó hoặc thêm địa chỉ mới khi muốn thay đổi địa chỉ giao hàng.
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6 d-flex align-items-center'>
          <img src={step_order_9} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 6:</strong>
          </p>
          <div className='ml-30'>
            <div className='how-to-do'>
              <p>Quý khách lựa chọn hình thức giao hàng OmiSpeed </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_order_10} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 7:</strong>
          </p>
          <div className='ml-30'>
            <div className='how-to-do'>
              <p>Quý khách lựa chọn hình thức thanh toán COD</p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_order_11} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 8:</strong>
          </p>
          <div className='ml-30'>
            <div className='how-to-do'>
              <p>
                Quý khách lựa chọn và sử dụng Voucher giảm giá (nếu có) phù hợp và tương ứng với số tiền trên tổng hóa
                đơn quý khách mua hàng
              </p>
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_order_12} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-5 col-sm-5'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Bước 9:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do mb-15'>
              <p>
                Cuối cùng, quý khách nhấp vào Mua hàng để hoàn tất đơn hàng. Omi pharma sẽ gửi thông báo đến địa chỉ
                email.{' '}
              </p>
              <p className='custom-color hightlight'>Dược sĩ Omi sẽ liên hệ tới Quý khách về thông tin đơn hàng.</p>
            </div>
            <div className='image-box col-lg-6 col-sm-6' style={{ marginLeft: 'auto' }}>
              <img src={step_order_13} alt='Omi Pharma website screenshot' />
            </div>
          </div>
        </div>
        <div className='image-box col-lg-6 col-sm-6'>
          <img src={step_order_14} alt='Omi Pharma website screenshot' />
        </div>
      </div>

      <div className='row mb-45'>
        <div className='col-lg-11 col-sm-11'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Lưu ý:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do'>
              <p>
                Quý khách sẽ nhận được thông tin tích điểm OmiPoint (đối với tài khoản đã đăng nhập) sau khi mua hàng
                thành công. Quý khách có thể sử dụng điểm OmiPoint để thanh toán một phần đơn hàng (giá trị đơn hàng tại
                thời điểm mua hàng, và quy đổi theo quy định OmiPoint của OmiPharma)
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-15'>
        <div className='col-lg-11 col-sm-11'>
          <p>
            <img src={arrow} className='step-icon' alt='Omi Pharma website screenshot' />
            <strong>Đặt hàng thành công:</strong>
          </p>
          <div className='ml-30 step-content'>
            <div className='how-to-do'>
              <p>
                Sau khi đặt hàng thành công trên hệ thống, hệ thống sẽ gửi mail xác nhận đặt hàng thành công cho khách
                hàng
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavInstructOrder
