import '../../assets/scss/auth.scss'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Register from './Register'
import { FaTimes } from 'react-icons/fa'
import { useState } from 'react'
import LoadingCpn from '../loading'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { authStore } from '../../app/use-selector'
import { clearRegisterState, next } from '../../app/features/auth'
// import $ from 'jquery'
import { useTranslation } from 'react-i18next'
const ModalAuth: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const auth = useAppSelector(authStore)
  const [typeRender, setTypeRender] = useState<number>(0)

  const changeRender = (value: number) => {
    setTypeRender(value)
  }

  const clearAll = () => {
    setTypeRender(0)
    dispatch(next(1))
    dispatch(clearRegisterState())
  }

  const hidenQr = () => {
    const closeEl = document.getElementById('clodeModalInput')
    closeEl?.click()
    // $('body').removeClass('modal-open').css({ overflow: 'auto' })
    // document.body.removeAttribute('style')
    // const element = document.getElementById('modalAuthInput')
    // element?.classList.remove('show')
    // element?.removeAttribute('role')
    // element?.removeAttribute('aria-modal')
    // element?.setAttribute('aria-hidden', 'true')
    // $('.modal-backdrop').remove()
    // $('#modalAuthInput').hide()
  }

  const closeModal = () => {
    if (typeRender != 0) {
      if (auth.registerStep.step > 1) {
        if (window.confirm(typeRender === 1 ? t('cancelRegister') : t('cancelForgotPw'))) {
          clearAll()
          hidenQr()
        }
        return
      }
      setTypeRender(0)
    }

    hidenQr()
  }

  return (
    <div className='modal-auth-input'>
      <div
        className='modal fade'
        data-bs-backdrop='false'
        data-bs-keyboard='false'
        tabIndex={-1}
        id='modalAuthInput'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl'>
          <div className='modal-content modal-auth-input-content'>
            {auth.loading && <LoadingCpn />}
            <div className='modal-auth-input-content__header'>
              <p id='clodeModalInput' data-bs-dismiss='modal'></p>
              <FaTimes
                // data-bs-dismiss='modal'
                size={24}
                color='gray'
                style={{ cursor: 'pointer' }}
                aria-label='Close'
                onClick={closeModal}
              />
            </div>
            <div className='modal-auth-input-content__body'>
              {typeRender === 0 && <Login changeRender={(value: number) => changeRender(value)} />}
              {typeRender === 1 && <Register changeRender={(value: number) => changeRender(value)} />}
              {typeRender === 2 && <ForgotPassword changeRender={(value: number) => changeRender(value)} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalAuth
