import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { CityType, DistrictType, WardType } from '../../../models/types'
import { getCityList, getDistrictList, getWardList } from '../../../app/app-api'
import { toastSuccessErr } from '../../../helpers/toast'
import { AddressItemType } from '../../../models/address'
import {
  getAddressList,
  putAddressItem,
  addAddressItem,
  deleteAddressItem,
} from '../../../app/features/userAddress/userAddress-api'

export const slice = createSlice({
  name: 'userAccountPage',
  initialState: {
    loading: false,
    cityList: [] as CityType[],
    districtList: [] as DistrictType[],
    wardList: [] as WardType[],
    userAddressList: [] as AddressItemType[],
    relativeAddressList: [] as any,
    accountInfo: {} as any,
  },
  reducers: {
    changeRelativeAddressList: (state, action) => {
      state.relativeAddressList = action.payload
    },
    changeAccountInfo: (state, action) => {
      state.accountInfo = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCityList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCityList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.cityList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(getCityList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getDistrictList.pending, (state) => {
        state.loading = true
      })
      .addCase(getDistrictList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.districtList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(getDistrictList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getWardList.pending, (state) => {
        state.loading = true
      })
      .addCase(getWardList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.wardList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(getWardList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getAddressList.pending, (state) => {
        state.loading = true
      })
      .addCase(getAddressList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.userAddressList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(getAddressList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(putAddressItem.pending, (state) => {
        state.loading = true
      })
      .addCase(putAddressItem.fulfilled, (_state, action) => {
        const { code, message } = action.payload as any
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(putAddressItem.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(addAddressItem.pending, (state) => {
        state.loading = true
      })
      .addCase(addAddressItem.fulfilled, (_state, action) => {
        const { code, message } = action.payload as any
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(addAddressItem.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(deleteAddressItem.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteAddressItem.fulfilled, (_state, action) => {
        const { code, message } = action.payload as any
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(deleteAddressItem.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectCityList = (state: RootState) => state[slice.name].cityList
export const selectWardList = (state: RootState) => state[slice.name].wardList
export const selectDistrictList = (state: RootState) => state[slice.name].districtList
export const selectUserAddressList = (state: RootState) => state[slice.name].userAddressList
export const selectRelativeAddressList = (state: RootState) => state[slice.name].relativeAddressList
export const selectAccountInfo = (state: RootState) => state[slice.name].accountInfo

export const { changeRelativeAddressList, changeAccountInfo } = slice.actions

export const UserAccountReducer = slice.reducer
